import { Fragment } from "react";
import Layout from "../components/layout/Layout";
import Card from "../components/layout/Card";
import classes from "./index.module.css";

function HomePage() {
  return (
    <Layout>
      <h1 className={classes.title}>Academy Toolbox Apps</h1>
      <div className={classes.cardContainer}>
        <Card
          title="Visual Multiplication Calculator"
          text="This calculator provides visual strategies for multiplication problems. More operations (addition, subtraction, division) will be coming soon!"
          link="/calculator"
        ></Card>
      </div>
    </Layout>
  );
}

export default HomePage;
