import classes from "./Information.module.css";
function Information(props) {
  return (
    <div className={classes.modal}>
      <p>
        Think of this as a very simple calculator that (for now) can only solve
        multiplication problems. You should enter a number, then an operation (x
        or * for multiplication) and then another number. Do not start with a
        negative number.
      </p>
      <p>
        You will notice that some strategies are greyed out. This is
        intentional. Some strategies are better with larger numbers (like the
        Area Model Intermediate), and others are good with smaller numbers (like
        the Multiplication Array). The place value chart is great for
        multiplying single-digit numbers by numbers up to 3 digits.
      </p>
      <p>
        Because the focus is visual strategies, none of these strategies will
        multiply numbers that are in the thousands place.
      </p>
    </div>
  );
}
export default Information;
