import classes from "./PlaceValueChartNumbers.module.css";
import { useState, useEffect, useRef } from "react";
import {
  expandedForm,
  regroupedMultiplication,
} from "../../../../javascript/helperFunctions";
import AnimationStage from "../../AnimationStage";
import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";
import Instructions from "../../Instructions";

function PlaceValueChartNumbers() {
  const [state, dispatch] = useCalculatorState();
  let { firstValue, secondValue } = state.problem;
  const { solution, query } = state.problem;
  const animationStage = state.animationStage;

  // let { solution, , firstValue, secondValue } = props.data;
  if (parseFloat(secondValue) > parseFloat(firstValue)) {
    const temp = firstValue;
    firstValue = secondValue;
    secondValue = temp;
  }

  let firstValueExpandedForm = expandedForm(firstValue);
  let secondValueExpandedForm = expandedForm(secondValue);
  const [screenSize, setScreenSize] = useState();
  const [rectangleWidth, setRectangleWidth] = useState();
  const [rectangleHeight, setRectangleHeight] = useState();
  const [firstValueDigits, setFirstValueDigits] = useState(0);
  const [secondValueDigits, setSecondValueDigits] = useState(0);

  // const [animationStage, setAnimationStage] = useState(0);
  // Note-I am writing this to work with up to 3 digit numbers.

  // FIX ME: This doesn't work with numbers that start with 01 x10 for example.

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize) {
      setRectangleHeight(screenSize.height / 3);
      setRectangleWidth(Math.max((screenSize.width * 6) / 7, 280));
    }
  }, [screenSize]);

  useEffect(() => {
    dispatch({ type: "SET_MAX_STAGE", value: 4 });
    dispatch({ type: "RESET_CURRENT_STAGE" });
    if (parseInt(firstValue) >= 1000) {
      console.log("the first number is too big to show in our diagrams.");
    } else if (parseInt(firstValue) >= 100) {
      setFirstValueDigits(3);
    } else if (parseInt(firstValue) >= 10) {
      setFirstValueDigits(2);
    } else if (parseInt(firstValue) >= 1) {
      setFirstValueDigits(1);
    }
    if (parseInt(secondValue) >= 1000) {
      // Fix me-post error.
      console.log("the second number is too big to show in our diagrams.");
    } else if (parseInt(secondValue) >= 100) {
      setSecondValueDigits(3);
    } else if (parseInt(secondValue) >= 10) {
      setSecondValueDigits(2);
    } else if (parseInt(secondValue) >= 1) {
      setSecondValueDigits(1);
    }
  }, [dispatch, firstValue, secondValue]);
  const regroupedObject = regroupedMultiplication(firstValue, secondValue);

  const animationStageArray = [
    {
      text: `To solve ${query}, first we want to place the larger number on the place value chart. Notice that this chart is broken into columns representing the place value of each digit--reading from right to left, we have the ones, tens, hundreds, and thousands. How do you think we will place the larger number on the place value chart?`,
    },
    {
      text: `To show the larger number on this place value chart,  we want to use Expanded Form.  Expanded Form is when you break a number into digits--into ones, tens, hundreds, and so on. We can write ${firstValue} as ${firstValueExpandedForm.join(
        "+"
      )}.  ${
        firstValueDigits < 2
          ? `Because ${firstValue} only has one digit, the Expanded Form looks the same as the normal form. `
          : ""
      }Imagine you were going to add 40 to the place value chart. We would turn 40 into 4 tens, and put a 4 in the tens place.  We have added the larger value to the place value chart--notice we removed any extra zeroes in the expanded form. How do you think we will multiply in the next step?`,
    },
    {
      text: `Multiplication is the same thing as repeated addition. ${
        secondValue === 1 || secondValue === "1"
          ? `Because we are multiplying by 1, we only have to write out ${firstValue} once.`
          : `So for each column, we can write the value in that column out ${secondValue} times.`
      } We can add the repeated values in the bottom row, and we are almost at our answer. There might be another step though.`,
    },
    {
      text: `There is one important rule with a place value chart: Your final answer should never have more than 10 in a place value column. 10 ones is the same as 1 ten. 10 tens is the same as 1 hundred, and 10 hundreds is the same as 1 thousand.  If we do have more than 10 in any place value column in our answer, then we need to regroup. Do you see any place value columns in our answer that need to be regrouped?  To prepare for regrouping, we broke any numbers greater than 10 into expanded form.`,
    },
    {
      text: `Look at the solution column below. If you need to, look back to the previous step to see the difference. Starting from the ones column, if there were any numbers greater 10 or more, we regrouped them. ${
        regroupedObject
          ? regroupedObject.changed
            ? `In this case, you will notice that we did regroup the ${
                regroupedObject.onesChanged ? "ones, and " : ""
              } ${regroupedObject.tensChanged ? "tens, and " : ""} ${
                regroupedObject.hundredsChanged ? "hundreds, and " : ""
              }these changes are shown below.`
            : `in this case, we did not have to regroup.`
          : ""
      } The final answer, as seen below, is ${solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    console.log("in get current dimension");
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let placeValuesWords = ["thousands", "hundreds", "tens", "ones"];

  let rows = [];
  if (firstValueDigits > placeValuesWords.length || firstValueDigits < 1) {
    // handle error here
    console.log("too big");
  } else {
    columns = placeValuesWords.slice(-(firstValueDigits + 1));
  }
  // for (let i = 0; i < firstValueDigits; i++) {
  //   columns.push(i);
  // }
  // Notice i <= second value-we want one extra row for the solution row.
  for (let i = 0; i <= secondValue; i++) {
    rows.push(i);
  }

  // and now for the animations and CSS classes

  return (
    <div className={classes.wrapper}>
      <div>
        <AnimationStage />
        <br />
        <Instructions text={animationStageArray[animationStage]?.text} />
      </div>
      <p style={{ fontSize: `${16}px` }}></p>

      <div
        className={classes.arrayWrapper}
        ref={arrayHeight}
        style={{
          height: `${rectangleHeight}px`,
          width: `${rectangleWidth}px`,
        }}
      >
        {columns.map(function (e, index) {
          return (
            <div
              className={`${classes[`column-${index}`]} ${classes.allColumns}`}
              key={`column${e.toString()}`}
              style={{
                width: `${rectangleWidth / columns.length}px`,
              }}
            >
              {/* we offset the top thousands/hundreds for mobile view for spacing */}
              {animationStage > -1 && (
                <div
                  className={
                    index % 2 === 0
                      ? classes.expandedFormTop
                      : classes.expandedFormTopOffset
                  }
                  style={{ width: `${rectangleWidth / columns.length}px` }}
                >
                  <span>{columns[index]}</span>
                </div>
              )}

              {rows.map(function (f, index2) {
                return (
                  <div
                    className={`${classes[`row-${index2}`]} ${classes.allRows}`}
                    key={`row${f.toString()}col${f.toString}`}
                    style={{
                      height: `${rectangleHeight / rows.length}px`,
                      // height: `100%`,
                      borderTopWidth:
                        index2 === rows.length - 1 ? "5px" : "2px",
                    }}
                  >
                    {/* this is the top row, rendered first. */}
                    {animationStage == 1 && index > 0 && index2 == 0 && (
                      <p>
                        {firstValueExpandedForm[index - 1]
                          .toString()
                          .slice(0, 1)}
                      </p>
                    )}

                    {/* This is the duplicated contents of the firstValue */}
                    {animationStage > 1 &&
                      index > 0 &&
                      index2 !== rows.length - 1 && (
                        <p>
                          {firstValueExpandedForm[index - 1]
                            .toString()
                            .slice(0, 1)}
                        </p>
                      )}
                    {/* This is the solution row: (but not regrouping*/}
                    {animationStage > 1 &&
                      animationStage < 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {firstValueExpandedForm[index - 1]
                            .toString()
                            .slice(0, 1) * secondValue}
                        </p>
                      )}

                    {/* This is the solution row: expanded form (but not regrouping*/}
                    {animationStage == 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {expandedForm(
                            firstValueExpandedForm[index - 1]
                              .toString()
                              .slice(0, 1) * secondValue
                          ).join("+")}
                        </p>
                      )}
                    {/* this is the total text off to the left */}
                    {animationStage > 1 &&
                      index === 0 &&
                      index2 === rows.length - 1 &&
                      screenSize.width > 600 && (
                        <p
                          className={classes.totalLabel}
                          style={{
                            fontSize: `${16}px`,
                            // top: rectangleHeight / 3,
                          }}
                        >
                          Total
                        </p>
                      )}

                    {animationStage > 1 &&
                      index === 0 &&
                      index2 === rows.length - 2 && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                            position: `relative`,
                            left: `${rectangleWidth / columns.length / 3}px`,
                          }}
                        >
                          +
                        </p>
                      )}
                    {/* this is the regrouping values for thousands */}
                    {animationStage === 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 4 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.thousands}
                        </p>
                      )}
                    {/* This is the regrouping values for hundreds: */}
                    {animationStage === 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 3 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.hundreds}
                        </p>
                      )}
                    {/* This is the regrouping values for tens: */}

                    {animationStage == 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 2 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.tens}
                        </p>
                      )}

                    {/* This is the regrouping values for ones: */}

                    {animationStage == 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 1 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.ones}
                        </p>
                      )}
                  </div>
                );
              })}
            </div>
          );
        })}

        {animationStage > 1 && (
          <p
            className={classes.secondValue}
            style={{
              fontSize: `${16}px`,
              top: rectangleHeight / 3,
            }}
          >
            {secondValue}
          </p>
        )}
      </div>
    </div>
  );
}

export default PlaceValueChartNumbers;
