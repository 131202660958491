import classes from "./AreaModelIntermediate.module.css";
import { Fragment, useState, useEffect, useRef } from "react";
import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";
import AnimationStage from "../../AnimationStage";
import { expandedForm } from "../../../../javascript/helperFunctions";
import Instructions from "../../Instructions";
function MultiplicationArray() {
  // access context:
  const [state, dispatch] = useCalculatorState();
  const { firstValue, secondValue, query } = state.problem;
  const { solution } = state.problem;

  const animationStage = state.animationStage;

  const [screenSize, setScreenSize] = useState();
  const [rectangleWidth, setRectangleWidth] = useState();
  const [rectangleHeight, setRectangleHeight] = useState(300);
  const [firstValueDigits, setFirstValueDigits] = useState(1);
  const [secondValueDigits, setSecondValueDigits] = useState(1);

  // const [animationStage, setAnimationStage] = useState(0);
  // Note-I am writing this to work with up to 3 digit numbers.

  // FIX ME: This doesn't work with numbers that start with 01 x10 for example.

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }
    if (!arrayHeight) {
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize) {
      setRectangleHeight(screenSize.height / 3);
      setRectangleWidth((screenSize.width * 4) / 7);
    }
  }, [screenSize]);

  useEffect(() => {
    dispatch({ type: "SET_MAX_STAGE", value: 3 });
    dispatch({ type: "RESET_CURRENT_STAGE" });

    if (parseInt(firstValue) >= 1000) {
      // Fix me-post error.
      console.log("the first number is too big to show in our diagrams.");
    } else if (parseInt(firstValue) >= 100) {
      setFirstValueDigits(3);
    } else if (parseInt(firstValue) >= 10) {
      setFirstValueDigits(2);
    } else if (parseInt(firstValue) >= 1) {
      setFirstValueDigits(1);
    }
    if (parseInt(secondValue) >= 1000) {
      // Fix me-post error.
      console.log("the second number is too big to show in our diagrams.");
    } else if (parseInt(secondValue) >= 100) {
      setSecondValueDigits(3);
    } else if (parseInt(secondValue) >= 10) {
      setSecondValueDigits(2);
    } else if (parseInt(secondValue) >= 1) {
      setSecondValueDigits(1);
    }
  }, [firstValue, secondValue]);

  // if firstValueDigits and secondValue digits =1, then that means we didn't do any exponent form.
  const areBothValuesUnder10 = firstValueDigits < 2 && secondValueDigits < 2;

  const animationStageArray = [
    {
      text: `${
        areBothValuesUnder10
          ? `This strategy is intended for larger numbers, but we can demonstrate it with ${query}.`
          : ""
      } To solve ${query}, first we want to remember that the area of a rectangle is the space inside of it.  You can find the area of a rectangle by multiplying two sides that are next to each other--the length and the width.  So we have labeled the length and the width here.  We have solved similar problems using equal groups before, but maybe we don't want to draw out ${firstValue} groups of ${secondValue}. Can you think of an easier way to represent this problem visually?`,
    },
    {
      text: `To show large numbers on an area model,  we want to use Expanded Form.  Expanded Form is when you break a number into digits--into ones, tens, hundreds, and so on. In the drawing below, we broke both numbers into expanded form. ${
        areBothValuesUnder10
          ? "In this case, since both values have a single digit, the expanded form for each value is equal to the original number."
          : ""
      } It is worth noticing that the rectangles are not "to scale."  This means that the sizes of the inner rectangles don't actually matter for this diagram.  Do you have a guess for our next step?`,
    },
    {
      text: `${
        areBothValuesUnder10
          ? `With a larger number, you would see multiple smaller rectangles that make up the bigger rectangle. We could find the area of those smaller rectangles by multiplying their length and width. Then we would add the area of these smaller rectangles, to get the larger rectangle. However, since we are working with single digit numbers, we do not need to break the rectangle into smaller parts. All we have to do is multiply the length and width to get the area: ${query} = ${solution}.`
          : "Next, we will find the area of each of the smaller rectangles.  We do this by multiplying the length and width of the smaller rectangles.  Then, we add the area of each of the smaller rectangles, to get the larger rectangle."
      } `,
    },
    {
      text: `To recap everything so far: Area is the space inside a flat shape, like a rectangle. We find the area of a rectangle by multiplying the length and the width--the two different sides of the rectangle.  If the numbers are very large, it is helpful to break the numbers into expanded form, and represent this by breaking the rectangle into parts that represent the ones, tens, and hundreds. Once we find the area of the smaller rectangles, we can add all the rectangles together to get our final answer: ${solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let rows = [];

  for (let i = 0; i < firstValueDigits; i++) {
    columns.push(i);
  }
  for (let i = 0; i < secondValueDigits; i++) {
    rows.push(i);
  }

  // and now for the animations and CSS classes

  let firstValueExpandedForm = expandedForm(firstValue);
  let secondValueExpandedForm = expandedForm(secondValue);

  return (
    <div className={classes.wrapper}>
      <div>
        <AnimationStage />
        <br />
        <Instructions text={animationStageArray[animationStage]?.text} />
      </div>

      {animationStage === 0 && (
        <p style={{ fontSize: `${16}px` }}>{firstValue}</p>
      )}
      {animationStage > 0 && (
        <p
          className={classes.invisibleParagraph}
          style={{ fontSize: `${16}px` }}
        >
          x{" "}
        </p>
      )}

      <div
        className={classes.arrayWrapper}
        ref={arrayHeight}
        style={{ height: `${rectangleHeight}px`, width: `${rectangleWidth}px` }}
      >
        {columns.map(function (e, index) {
          return (
            <div
              className={`${classes[`column-${index}`]} ${classes.allColumns}`}
              key={`column${e.toString()}`}
              style={{
                width: `${rectangleWidth / firstValueDigits}px`,
              }}
            >
              {animationStage > 0 && (
                <div
                  className={classes.expandedFormTop}
                  style={{ width: `${rectangleWidth / firstValueDigits}px` }}
                  key={`expandedFormTop${e.toString()}`}
                >
                  <span key={`expandedFormValue${e.toString()}`}>
                    {firstValueExpandedForm[index]}
                  </span>
                  {index < columns.length - 1 && (
                    <span
                      key={`plus${e.toString()}`}
                      className={classes.topPlus}
                      style={{
                        left: `${rectangleWidth / firstValueDigits - 10}px`,
                      }}
                    >
                      +
                    </span>
                  )}
                </div>
              )}

              {rows.map(function (f, index2) {
                return (
                  <div
                    className={`${classes[`row-${index2}`]} ${classes.allRows}`}
                    key={`coldiv${e.toString()}row${f.toString}${index2}`}
                    style={{
                      height: `${rectangleHeight / secondValueDigits}px`,
                    }}
                  >
                    {/* Now we add the expanded form on the left if we are in the leftmost column */}
                    {index === 0 && animationStage > 0 && (
                      <div
                        key={`expandedFormContainer${f.toString()}col${
                          f.toString
                        }`}
                        className={classes.expandedFormLeft}
                        style={{
                          height: `${rectangleHeight / secondValueDigits}px`,
                        }}
                      >
                        <p
                          key={`expandedFormText${f.toString()}col${
                            f.toString
                          }`}
                        >
                          {secondValueExpandedForm[index2]}
                        </p>
                        {index2 < rows.length - 1 && (
                          <span
                            key={`expandedFormPlus${f.toString()}col${
                              f.toString
                            }`}
                            className={classes.leftPlus}
                            style={{
                              top: `${
                                rectangleHeight / secondValueDigits - 10
                              }px`,
                            }}
                          >
                            +
                          </span>
                        )}
                      </div>
                    )}
                    {/* This is the contents of each cell-the multiplication */}
                    {animationStage > 1 && (
                      <p key={`cellContents${f.toString()}col${f.toString}`}>
                        {firstValueExpandedForm[index]} *{" "}
                        {secondValueExpandedForm[index2]} ={" "}
                        {firstValueExpandedForm[index] *
                          secondValueExpandedForm[index2]}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}

        {animationStage === 0 && (
          <p
            className={classes.secondValue}
            style={{
              fontSize: `${16}px`,
              top: rectangleHeight / 3,
            }}
          >
            {secondValue}
          </p>
        )}

        {animationStage > 1 &&
          (firstValueDigits > 1 || secondValueDigits > 1) && (
            <div
              className={classes.rightAddition}
              style={{ left: `${rectangleWidth + 10}px` }}
            >
              {firstValueExpandedForm.map((e, index) => {
                return (
                  <Fragment key={`div${index}`}>
                    {secondValueExpandedForm.map((e, index2) => {
                      return (
                        <Fragment key={`innerFragment${index}${index2}`}>
                          <p key={`p${index}${index2}`}>
                            {(index !== 0 || index2 !== 0) && <span>+</span>}
                            {firstValueExpandedForm[index] *
                              secondValueExpandedForm[index2]}
                          </p>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
              <p className={classes.rightSolution}>{solution}</p>
            </div>
          )}
      </div>
    </div>
  );
}

export default MultiplicationArray;
