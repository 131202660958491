import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import classes from "./Calculator.module.css";
import calculateQuery from "../../javascript/calculateQuery";
import { useCalculatorState } from "../../contexts/CalculatorStateProvider";
function Calculator(props) {
  const queryRef = useRef();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  //   query = document.getElementById("query").value;
  const location = useLocation();

  const [state, dispatch] = useCalculatorState();

  const queryParams = new URLSearchParams(location.search);
  const operandArray = ["+", "-", "*", "/", "x"];
  let isError = true;
  let operand = "";
  let firstValueParam = "";
  let operandParam = "";
  let secondValueParam = "";
  let idParam = "";
  let validParam;

  try {
    firstValueParam = parseInt(queryParams.get("first-value"));
    operandParam = queryParams.get("operand");
    secondValueParam = parseInt(queryParams.get("second-value"));
    idParam = parseInt(queryParams.get("id"));
    validParam = parseInt(queryParams.get("valid"));

    if (validParam === "false") {
      isError = true;
    } else {
      isError = false;
    }
    if (operandArray.includes(operandParam)) {
      operand = operandParam;
    }
  } catch (e) {
    console.log(e);
    isError = true;
  }

  useEffect(() => {
    // This code will run whenever any of the parameters change
    if (!firstValueParam && !secondValueParam) {
      return;
    } else {
      setQuery(`${firstValueParam}${operand}${secondValueParam}`);

      // if there is no input, then don't change anything:
      const solution = calculateQuery(query);
      console.log("this is the solution", solution);
      if (isError === true) {
        solution.valid = false;
      }
      dispatch({
        type: "UPDATE_PROBLEM",
        variable: "problem",
        value: solution,
      });
      dispatch({ type: "RESET_CURRENT_STAGE" });
      props.updateQuery(query);
      props.updateSolution(solution);
    }

    // You can put any code you want to run on parameter changes here.
  }, [firstValueParam, operand, secondValueParam, isError, validParam, query]);

  function submitHandler(e) {
    e.preventDefault();
    const query = queryRef.current.value;
    // Eventually I will remove the updateQuery call.
    // props.resetQuery();
    // props.updateQuery(query);
    const solution = calculateQuery(query);

    // dispatch({
    //   type: "UPDATE_PROBLEM",
    //   variable: "problem",
    //   value: solution,
    // });
    // dispatch({ type: "RESET_CURRENT_STAGE" });
    if (
      solution.firstValue &&
      solution.secondValue &&
      solution.operand
      // !solution.isError
    ) {
      navigate(
        `/calculator?first-value=${encodeURIComponent(
          solution.firstValue
        )}&operand=${encodeURIComponent(
          solution.operand
        )}&second-value=${encodeURIComponent(solution.secondValue)}&valid=${
          solution.valid ? "true" : "false"
        }&id=${Date.now()}`
      );
    } else {
      navigate(`/calculator?valid=false`);
    }
  }
  return (
    <form className={classes.wrapper} onSubmit={submitHandler}>
      <h2>
        Visual Multiplication Calculator{" "}
        <sup>
          <button
            type="button"
            onClick={props.onInformationClick}
            className={classes.information}
          >
            ⓘ
          </button>
        </sup>
      </h2>

      <input id="query" ref={queryRef} />
      <button className={classes.button}>Solve</button>
    </form>
  );
}
export default Calculator;
