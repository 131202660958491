export function getAnimationStageArray(
  firstValue,
  secondValue,
  firstValueExpandedForm,
  secondValueExpandedForm,
  solution,
  regroupedObject
) {
  return [
    {
      text: `The drawing below is a place value chart.  Notice that this chart is broken into columns. Place value is one way to break numbers into parts. We can break numbers into ones, tens, hundreds, and thousands. We will start by breaking the first number into these parts--into ones, tens, hundreds, and so on.  How many disks do you think will go in the ones place?`,
    },
    {
      text: `To show the first number on this place value chart,  we want to use Expanded Form.  Expanded Form is when you break a number into digits--into ones, tens, hundreds, and so on. We can write ${firstValue} as ${firstValueExpandedForm.join(
        "+"
      )} . Imagine you were going to add 400 to the place value chart. We would turn 400 into 4 hundreds, and put 4 disks in the hundreds column.  We have added the first value to the place value chart. What do you think ${secondValue} will look like in expanded form?`,
    },
    {
      text: `We can show addition by adding more disks. To know how many disks to create, we need to turn ${secondValue} into expanded form.  ${secondValue} in expanded form is ${secondValueExpandedForm.join(
        "+"
      )}. We can add disks in the second row to show this.`,
    },
    {
      text: `There is one important rule with a place value chart: Your final answer should never have more than 10 in a place value column. 10 ones is the same as 1 ten. 10 tens is the same as 1 hundred, and 10 hundreds is the same as 1 thousand.  If we do have more than 10 in any place value column in our answer, then we need to regroup. Below, you will see the same number of disks in each column, but to prepare for regrouping, we reorganized the disks into groups of ten if possible.`,
    },
    {
      text: `If there are more than 10 disks in a column, then those disks should be moving over to the column to the left. 10 ones would become 1 ten, 10 tens would become one hundred, and so on.  This process is called regrouping.  ${
        regroupedObject
          ? regroupedObject.changed
            ? `In this case, you will notice that we did regroup the ${
                regroupedObject.onesChanged ? "ones, and " : ""
              } ${regroupedObject.tensChanged ? "tens, and " : ""} ${
                regroupedObject.hundredsChanged ? "hundreds, and " : ""
              }these changes are shown below.`
            : `In this case, we did not have to regroup.`
          : ""
      } The final answer, as seen below, is ${solution}.`,
    },
  ];
}
