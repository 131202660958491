import { Fragment } from "react";
import Layout from "../components/layout/Layout";
import Card from "../components/layout/Card";
import classes from "./NotFound.module.css";

function NotFound() {
  return (
    <Layout>
      <h1 className={classes.title}>Error 404: Page not found</h1>
    </Layout>
  );
}

export default NotFound;
