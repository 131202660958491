import React, { useState } from "react";
import axios from "axios";
import classes from "./StoryEditor.module.css"; // Assuming you have css modules

function StoryEditor(props) {
  const [inputValue, setInputValue] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/stories", {
        section1: inputValue,
      });
      console.log(response.data); // You can handle your response here
    } catch (error) {
      console.error(error);
    }
  };

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <form className={classes.wrapper} onSubmit={submitHandler}>
      <h3> Generate Any Story</h3>
      <label htmlFor="input1">Prompt</label>
      <input id="input1" value={inputValue} onChange={inputChangeHandler} />
      <button type="submit">Submit</button>
    </form>
  );
}

export default StoryEditor;
