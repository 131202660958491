import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

import classes from "./ShowStory.module.css";

import Modal from "../../../components/layout/Modal";
import Layout from "../../../components/layout/Layout";
function ShowStory(props) {
  const [story, setStory] = useState();
  const { storyId } = useParams(); // get storyId from route params
  const [textValues, setTextValues] = useState([]);
  const [illustrationDescriptionValues, setIllustrationDescriptionValues] =
    useState([]);

  useEffect(() => {
    fetchStory();
  }, []);

  const fetchStory = async () => {
    try {
      const response = await axios.get(
        //fix me- dont hard code localhost
        `${process.env.REACT_APP_API_URL}/api/stories/early-readers/${storyId}`
      );
      console.log("response data", response.data);
      setStory(response.data.story);
      setTextValues(
        response.data.story.sections.map((section, index) => section.text)
      );
      setIllustrationDescriptionValues(
        response.data.story.sections.map(
          (section, index) => section.illustrationDescription
        )
      );
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  };

  const handleTextChange = (index) => (event) => {
    console.log("updating text of sections");
    const newTextValues = [...textValues];
    newTextValues[index] = event.target.value;
    setTextValues(newTextValues);
  };

  const handleIllustrationDescriptionChange = (index) => (event) => {
    console.log("updating illustration description");
    const newIllustrationDescription = [...illustrationDescriptionValues];
    illustrationDescriptionValues[index] = event.target.value;
    setTextValues(newIllustrationDescription);
  };
  const handleUpdateStory = async (e) => {
    e.preventDefault();

    try {
      const sections = textValues.map((textValue, index) => {
        return {
          text: textValue,

          illustrationDescription: illustrationDescriptionValues[index],
        };
      });
      console.log("about to send sections");
      const data = { sections };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/stories/early-readers/${storyId}`,
        data
      );
      console.log("successfully sent data");
    } catch (error) {
      console.error("error updating story", error);
    }
  };
  const handleGenerateImages = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/stories/early-readers/${storyId}/images`
      );
    } catch (error) {
      console.error("error adding images");
    }
  };

  return (
    <Layout>
      <Modal></Modal>
      <h1> View Story</h1>
      <h3>
        <Link to="/stories/edit">Create new Story </Link>
      </h3>
      <h3>
        <Link to="/stories/list">View Stories</Link>
      </h3>

      {story ? story.title : <p>Loading stories...</p>}
      <div className={classes.textContainer}>
        {story ? (
          story.sections.map((section, index) => {
            return (
              <div>
                <textarea
                  onChange={handleTextChange(index)}
                  className={classes.textArea}
                  key={index}
                >
                  {section.text}
                </textarea>
                <textarea
                  className={classes.textArea}
                  key={`illustration-${index}`}
                  onChange={handleIllustrationDescriptionChange(index)}
                >
                  {section.illustrationDescription}
                </textarea>
                <img
                  className={classes.img}
                  src="/calculator-clipart.png"
                  alt="test"
                ></img>
              </div>
            );
          })
        ) : (
          <p>Loading stories...</p>
        )}
      </div>
      <form onSubmit={handleUpdateStory}>
        <h3>Update content</h3>
        <button>Save changes</button>
      </form>
      <form onSubmit={handleGenerateImages}>
        <h3>Create images</h3>
        <button>Generate images</button>
      </form>
    </Layout>
  );
}

export default ShowStory;
