export const calculateImageSize = (screenSize, firstValue, secondValue) => {
  let targetTotalWidth = (screenSize.width * 2) / 3;
  let targetTotalHeight = (screenSize.height * 1) / 3;

  let testWidth = (screenSize.width * 2) / 3 / firstValue;
  let fixedWidth = (screenSize.height * 1) / 3 / secondValue;
  // let testHeight = screenSize.height / 2 / secondValue;
  // This is the situation where the drawing fits comfortably
  if (testWidth * secondValue < targetTotalHeight) {
    return testWidth;
  } else if (fixedWidth > 20) {
    // this else statement is to keep the squares from becoming tiny.
    return fixedWidth;
  } else if (30 * firstValue > targetTotalWidth) {
    // if the smallest desired size extends beyond the screen, then we can't allow that
    return testWidth;
  } else {
    // this is the smallest desired size.
    return 30;
  }
};

export function expandedForm(num) {
  let result = [];

  if (num) {
    const digits = num.toString().split("");

    for (let i = 0; i < digits.length; i++) {
      result.push(digits[i] * Math.pow(10, digits.length - i - 1));
    }
  }

  return result; // Remove the last ' + '
}
// right now this function is only set up to multiply a value up to 3 digits by a value that is 1 digit.
export function regroupedMultiplication(firstValue, secondValue) {
  const regrouped = {
    valid: true,
    changed: false,
    ones: 0,
    tens: 0,
    hundreds: 0,
    thousands: 0,
    onesChanged: false,
    tensChanged: false,
    hundredsChanged: false,
    thousandsChanged: false,
  };
  firstValue = parseFloat(firstValue);
  secondValue = parseFloat(secondValue);
  if (!firstValue || !secondValue) {
    regrouped.valid = false;
    return;
  }
  if (secondValue > firstValue) {
    let temp = firstValue;
    firstValue = secondValue;
    secondValue = temp;
  }
  if (secondValue >= 10) {
    regrouped.valid = false;
    return;
  }
  if (firstValue >= 1000) {
    regrouped.valid = false;
    return;
  }
  regrouped.ones = secondValue * parseInt(firstValue.toString().slice(-1));

  if (firstValue >= 10) {
    regrouped.tens =
      secondValue * parseInt(firstValue.toString().slice(-2, -1));
  }
  if (firstValue >= 100) {
    regrouped.hundreds =
      secondValue * parseInt(firstValue.toString().slice(-3, -2));
  }

  if (regrouped.ones >= 10) {
    regrouped.changed = true;
    regrouped.onesChanged = true;
    regrouped.tens += parseInt(regrouped.ones.toString().slice(0, 1));
    regrouped.ones = parseInt(regrouped.ones.toString().slice(-1));
  }
  if (regrouped.tens >= 10) {
    regrouped.changed = true;
    regrouped.tensChanged = true;
    regrouped.hundreds += parseInt(regrouped.tens.toString().slice(0, 1));
    regrouped.tens = parseInt(regrouped.tens.toString().slice(-1));
  }
  if (regrouped.hundreds >= 10) {
    regrouped.changed = true;
    regrouped.hundredsChanged = true;
    regrouped.thousands += parseInt(regrouped.hundreds.toString().slice(0, 1));
    regrouped.hundreds = parseInt(regrouped.hundreds.toString().slice(-1));
  }
  return regrouped;
}

export function regroupedAddition(firstValue, secondValue) {
  const regrouped = {
    valid: true,
    changed: false,
    ones: 0,
    tens: 0,
    hundreds: 0,
    thousands: 0,
    onesChanged: false,
    tensChanged: false,
    hundredsChanged: false,
    thousandsChanged: false,
  };
  firstValue = parseFloat(firstValue);
  secondValue = parseFloat(secondValue);
  if (!firstValue || !secondValue) {
    regrouped.valid = false;
    return;
  }
  // add values for ones, tens, hundreds first
  const larger = Math.max(firstValue, secondValue);

  if (firstValue >= 1000 || secondValue >= 1000) {
    regrouped.valid = false;
    return;
  }
  regrouped.ones =
    parseInt(firstValue.toString().slice(-1)) +
    parseInt(secondValue.toString().slice(-1));

  if (firstValue >= 10 && secondValue >= 10) {
    regrouped.tens =
      parseInt(firstValue.toString().slice(-2, -1)) +
      parseInt(secondValue.toString().slice(-2, -1));
  }
  if (firstValue >= 100 && secondValue >= 100) {
    regrouped.hundreds =
      parseInt(firstValue.toString().slice(-3, -2)) +
      parseInt(secondValue.toString().slice(-3, -2));
  }

  if (regrouped.ones >= 10) {
    regrouped.changed = true;
    regrouped.onesChanged = true;
    regrouped.tens += parseInt(regrouped.ones.toString().slice(0, 1));
    regrouped.ones = parseInt(regrouped.ones.toString().slice(-1));
  }
  if (regrouped.tens >= 10) {
    regrouped.changed = true;
    regrouped.tensChanged = true;
    regrouped.hundreds += parseInt(regrouped.tens.toString().slice(0, 1));
    regrouped.tens = parseInt(regrouped.tens.toString().slice(-1));
  }
  if (regrouped.hundreds >= 10) {
    regrouped.changed = true;
    regrouped.hundredsChanged = true;
    regrouped.thousands += parseInt(regrouped.hundreds.toString().slice(0, 1));
    regrouped.hundreds = parseInt(regrouped.hundreds.toString().slice(-1));
  }
  return regrouped;
}
export function calculateInitialRegroupingAddition(
  valueString,
  secondValueString,
  reversedIndexOfPlaceValue
) {
  return Math.floor(
    (Number(valueString[valueString.length + reversedIndexOfPlaceValue]) +
      Number(
        secondValueString[secondValueString.length + reversedIndexOfPlaceValue]
      )) /
      10
  );
}
export function getDigitModulusAddition(str, secondValueString, index) {
  return (Number(str[index]) + Number(secondValueString[index])) % 10;
}

export function NumberTo4DigitStringWithZeros(number) {
  let str = number.toString();
  while (str.length < 4) {
    str = "0" + str;
  }
  return str;
}

export function getGroupsOfTen(numberOfDisks) {
  // let totalAmountInRow = numberOfDisks * secondValue;
  return [Math.floor(numberOfDisks / 10), numberOfDisks % 10];
}

export function getNumberOfDisks(index, expandedForm, columns) {
  if (index === 0) {
    return 0;
  }
  if (!expandedForm || !columns) {
    console.log("There was an error inside getNumberOfDisks");
    return 0;
  }

  let element;
  // if (index > expandedForm.length) {
  //   return 0;
  // }
  // the first if is the "normal" case where the value fills ones, tens, hundreds. subtract 1 because thousands is always blank. The following cases are if the value is 1 shorter than the larger value, or 2 shorter, etc.
  if (columns.length === expandedForm.length + 1) {
    element = expandedForm[index - 1];
  } else if (columns.length === expandedForm.length + 2 && index > 1) {
    element = expandedForm[index - 2];
  } else if (columns.length === expandedForm.length + 3 && index > 2) {
    element = expandedForm[index - 3];
  } else {
    return 0;
  }
  if (
    element !== null &&
    element !== undefined &&
    typeof element.toString == "function"
  ) {
    return Number(element.toString().slice(0, 1));
  } else {
    console.log("invalid element in getNumberOfDisks:", element);
    return 0;
  }
}

export function createDisks(numberOfDisks, diskDiameter, classes) {
  return Array.from({ length: numberOfDisks }, (_, i) => (
    <div
      key={i}
      className={classes.disk}
      style={{ width: diskDiameter, height: diskDiameter }}
    ></div>
  ));
}
export function renderDiskContainersSmall(
  numberOfDisks,
  diskDiameter,
  animationCheck,
  borderCheck,
  classes
) {
  if (numberOfDisks > 5) {
    let firstRow = 5;
    let secondRow = numberOfDisks - 5;
    return (
      <div
        className={`${classes.diskContainerWrapper} ${borderCheck} ${animationCheck}`}
        style={animationCheck}
      >
        <div className={classes.diskContainer}>
          {createDisks(firstRow, diskDiameter)}
        </div>
        <div className={classes.diskContainer}>
          {createDisks(secondRow, diskDiameter)}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${classes.diskContainer} ${borderCheck} ${animationCheck}`}
        style={animationCheck}
      >
        {createDisks(numberOfDisks, diskDiameter)}
      </div>
    );
  }
}

export function addPlaceValue(
  firstValueString,
  secondValueString,
  index,
  columns
) {
  let firstValueDigit = 0;
  let numberOfColumns = columns.length;

  let secondValueDigit = 0;
  // firstValue
  while (firstValueString.length < 3) {
    firstValueString = "0" + firstValueString;
  }
  while (secondValueString.length < 3) {
    secondValueString = "0" + secondValueString;
  }
  // "normal" case where all columns are there. This means one of the values likely has 3 digits.
  if (numberOfColumns === 4 && index) {
    // say index = 1, that means you're in the hundreds place. you want string[0] to access hundreds place.
    firstValueDigit = Number(firstValueString[index - 1]);
    secondValueDigit = Number(secondValueString[index - 1]);
  } else if (numberOfColumns === 3) {
    // say index = 1, that means you are in the tens place. you want index to access tens place.
    firstValueDigit = Number(firstValueString[index]);
    secondValueDigit = Number(secondValueString[index]);
  } else if (numberOfColumns === 2) {
    firstValueDigit = Number(firstValueString[index + 1]);
    secondValueDigit = Number(secondValueString[index + 1]);
  }

  return firstValueDigit + secondValueDigit;
}

export function doAdditionalRegroupingNecessary(
  prevRegroup,
  curRegroup,
  previousDigitModulus
) {
  curRegroup += Math.floor((prevRegroup + previousDigitModulus) / 10);
  return curRegroup;
}
// function returns an object that includes styles and classes for the animated stage (stage 4)
export function checkIfAnimationNecessary(
  colNumber,
  numberOfDisks,
  rowNumber,
  columns,
  rows,
  animateColumn,
  rectangleWidth,
  rectangleHeight,
  animationStage,
  regroupAnimationCol0,
  regroupAnimationCol1,
  regroupAnimationCol2,
  classes
) {
  let animationCheck = {};
  // I'm defaulting addToThisRow to be the second row. This means all animations add to the first row.
  let addToThisRow = 1;
  const translateY = `${
    Math.max(rectangleHeight / rows.length, 50) * (addToThisRow - rowNumber)
  }px`;
  // leave this out if we are in the fisrt column
  let borderCheck = "";
  if (colNumber !== 0 && numberOfDisks >= 10 && animationStage === 4) {
    borderCheck = `${classes.diskBorder} ${classes.diskContainerAnimation}`;

    // This code is checking to see how many columns should have the transform applied-eg these are the regrouping columns that will shift. The column 3 would match regroupcolumn2.
    const regroupAmount =
      colNumber === 3
        ? regroupAnimationCol2
        : colNumber === 2
        ? regroupAnimationCol1
        : colNumber === 1
        ? regroupAnimationCol0
        : 0;
    // check to make sure it is this column's time to move (or be disappeared)
    if (colNumber >= animateColumn) {
      animationCheck = {
        transform:
          rowNumber <= regroupAmount
            ? `translateX(-${
                rectangleWidth / columns.length
              }px) translateY(${translateY})`
            : "",
        opacity: rowNumber <= regroupAmount ? `0` : "1",
      };
    }
  }
  return { styles: animationCheck, animatingClasses: borderCheck };
}
export function truncateCalculatedDisks(obj, numberOfTimes) {
  // Create a new object to store the truncated arrays
  const newObj = {};
  if (numberOfTimes === 0) {
    return obj;
  } else if (numberOfTimes > 2) {
    numberOfTimes = 2;
  } else if (numberOfTimes < 0) {
    return obj;
  }

  // Iterate over each [key, value] pair in the object
  for (const [key, value] of Object.entries(obj)) {
    newObj[key] = {};
    for (const [innerKey, innerValue] of Object.entries(value)) {
      // Remove the first value of the array and assign to the new object
      newObj[key][innerKey] = innerValue.slice(numberOfTimes);
    }
  }

  // Return the new object with truncated arrays
  return newObj;
}
export function calculateDisksByStageAndRowAddition(firstValue, secondValue) {
  const firstValueFourDigitString = NumberTo4DigitStringWithZeros(firstValue);
  const secondValueFourDigitString = NumberTo4DigitStringWithZeros(secondValue);
  let onesPlaceAdded = 0;
  let tensPlaceAdded = 0;
  let hundredsPlaceAdded = 0;
  let thousandsPlaceAdded = 0;
  const solution = parseInt(firstValue) + parseInt(secondValue);
  const solutionFourValueDigitString = NumberTo4DigitStringWithZeros(solution);
  //With step 3, we add 10s in the first row, and 1s in the second row. if there are not 10, then the ones go to the first row, and then nothing goes in the second row.
  try {
    onesPlaceAdded =
      parseInt(firstValueFourDigitString.slice(-1)) +
      parseInt(secondValueFourDigitString.slice(-1));
    // for the tens place, we are accessing the second to last character in the string and adding the int values
    tensPlaceAdded =
      parseInt(
        firstValueFourDigitString[firstValueFourDigitString.length - 2]
      ) +
      parseInt(
        secondValueFourDigitString[firstValueFourDigitString.length - 2]
      );
    hundredsPlaceAdded =
      parseInt(
        firstValueFourDigitString[firstValueFourDigitString.length - 3]
      ) +
      parseInt(
        secondValueFourDigitString[firstValueFourDigitString.length - 3]
      );
  } catch (e) {
    console.log(e);
  }
  const onesPlaceTensAndOnesArray = getGroupsOfTen(onesPlaceAdded);
  const tensPlaceTensAndOnesArray = getGroupsOfTen(tensPlaceAdded);
  const hundredsPlaceTensAndOnesArray = getGroupsOfTen(hundredsPlaceAdded);

  // when animation stage is 0, show no disks.
  const allCalculatedDisks = {
    animationStage0: {
      row0: [0, 0, 0, 0],
      row1: [0, 0, 0, 0],
    },
    animationStage1: {
      row0: [0, 0, 0, 0],
      row1: [0, 0, 0, 0],
    },
    animationStage2: {
      row0: [0, 0, 0, 0],
      row1: [0, 0, 0, 0],
    },
    animationStage3: {
      row0: [0, 0, 0, 0],
      row1: [0, 0, 0, 0],
    },
    // the fourth stage is just the solution. Nothing in second row.
    animationStage4: {
      row0: [0, 0, 0, 0],
      row1: [
        parseInt(solutionFourValueDigitString[0]),
        parseInt(solutionFourValueDigitString[1]),
        parseInt(solutionFourValueDigitString[2]),
        parseInt(solutionFourValueDigitString[3]),
      ],
      row2: [
        parseInt(solutionFourValueDigitString[0]),
        parseInt(solutionFourValueDigitString[1]),
        parseInt(solutionFourValueDigitString[2]),
        parseInt(solutionFourValueDigitString[3]),
      ],
    },
  };
  // everything else in stage 1 is 0;
  // fix me: is there a mistake here?
  allCalculatedDisks.animationStage1.row0 = [
    parseInt(firstValueFourDigitString[0]),
    parseInt(firstValueFourDigitString[1]),
    parseInt(firstValueFourDigitString[2]),
    parseInt(firstValueFourDigitString[3]),
  ];
  // This sets the first row equal to the previous stage's first row. Nothing changes. Then set the second row equal to the secondValue digits.
  allCalculatedDisks.animationStage2.row0 =
    allCalculatedDisks.animationStage1.row0;
  allCalculatedDisks.animationStage2.row1 = [
    parseInt(secondValueFourDigitString[0]),
    parseInt(secondValueFourDigitString[1]),
    parseInt(secondValueFourDigitString[2]),
    parseInt(secondValueFourDigitString[3]),
  ];
  // this shows the adding and grouping by 10s in animationStage3:
  // row 0 represents the 10s (if there any. eg 5+8 = 10 and 3).
  allCalculatedDisks.animationStage3.row0 = [
    thousandsPlaceAdded,
    hundredsPlaceAdded >= 10 ? 10 : 0,
    tensPlaceAdded >= 10 ? 10 : 0,
    onesPlaceAdded >= 10 ? 10 : 0,
  ];
  //  this is the ones. The row0 is the tens.
  allCalculatedDisks.animationStage3.row1 = [
    thousandsPlaceAdded,
    hundredsPlaceTensAndOnesArray[1],
    tensPlaceTensAndOnesArray[1],
    onesPlaceTensAndOnesArray[1],
  ];

  // This is tricky. AnimationStage4.row0 will have sets of 10 if animationstage3 did. Then, those will regroup with the animation.
  allCalculatedDisks.animationStage4.row0 =
    allCalculatedDisks.animationStage3.row0;
  return allCalculatedDisks;
}
