import classes from "./index.module.css";

import Modal from "../../components/layout/Modal";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
function StoriesPage() {
  return (
    <Layout>
      <Modal></Modal>
      <h1> Stories</h1>
      <h3>
        <Link to="/stories/edit">Create new Story </Link>
      </h3>
      <h3>
        <Link to="/stories/list">View stories</Link>
      </h3>
    </Layout>
  );
}
export default StoriesPage;
