import classes from "./AreaModel.module.css";
import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";
import { Fragment, useState, useEffect, useRef } from "react";
import { calculateImageSize } from "../../../../javascript/helperFunctions";
import AnimationStage from "../../AnimationStage";
import Instructions from "../../Instructions";
function MultiplicationArray() {
  const [state, dispatch] = useCalculatorState();
  const solution = state.problem;
  const { firstValue, secondValue } = solution;
  const animationStage = state.animationStage;

  const [screenSize, setScreenSize] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [additionDiagramFontSize, setAdditionDiagramFontSize] = useState();
  // const [animationStage, setAnimationStage] = useState(0);
  const [addingRowDiagramLeftShift, setAddingRowDiagramLeftShift] = useState(0);
  const [addingRowDiagramTopShift, setAddingRowDiagramTopShift] = useState(0);

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }
    if (!arrayHeight) {
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    // The max / target size is 2/3 width and 2/5 height.
    // width is a hard cut off, height can always add more.

    if (screenSize && firstValue && secondValue) {
      setImgWidth(calculateImageSize(screenSize, firstValue, secondValue));
    }
  }, [screenSize, firstValue, secondValue]);

  useEffect(() => {
    if (imgWidth) {
      let fontSize = imgWidth / 1.8;
      if (fontSize > 20) {
        fontSize = 20;
      }
      setAdditionDiagramFontSize(`${fontSize}`);

      setAddingRowDiagramLeftShift(`${imgWidth * firstValue + 40}`);
      setAddingRowDiagramTopShift(`${(imgWidth * 1) / 5}`);
    }
  }, [
    screenSize,
    firstValue,
    secondValue,
    imgWidth,
    addingRowDiagramLeftShift,
    addingRowDiagramTopShift,
  ]);

  useEffect(() => {
    dispatch({ type: "SET_MAX_STAGE", value: 3 });
    dispatch({ type: "RESET_CURRENT_STAGE" });
  }, [firstValue, secondValue]);

  let additionTranslation = `${firstValue}`;
  // FIX ME: This won't work with negative numbers.
  for (let i = 0; i < secondValue - 1; i++) {
    additionTranslation += ` + ${firstValue}`;
  }
  const animationStageArray = [
    {
      text: `Let's solve ${solution.query} with an Area Model. Similar to the Multiplication Array, we will create groups that represent the two numbers we are multiplying. We started by making ${firstValue} columns. How could you add to this rectangle in order to help solve this problem?`,
    },
    {
      text: `In the drawing below, the area of the rectangle is the space that it takes up on your screen.  You could calculate the area of the shape below by counting all the squares one by one.  That might take a while. Just like with the Multiplication Array, we could solve this problem by adding equal groups together, which might be a little faster.  We can read this problem as: ${additionTranslation} = ${solution.solution}.  But with large numbers, this would take a long time-- can you think of a faster strategy?`,
    },
    {
      text: `Now that we've solved the problem with addition, let's solve the problem with multiplication.  When you add the same number over and over again, that is the same thing as multiplication. Also, when you have equal groups of something, like ${firstValue} groups of ${secondValue}, that is the same thing as multiplying ${firstValue} x ${secondValue}. So we could write this multiplication sentence as ${firstValue} x ${secondValue} = ${solution.solution}. This is connected to how we find the area of a rectangle. If you multiply the length of a rectangle times the width of the rectangle, you get the area--you get everything inside the rectangle.`,
    },
    {
      text: `To recap everything so far: Area is the space inside a flat shape, like a rectangle. One way to calculate the area of a rectangle is to break it into tiny equal squares and then count the squares. An even faster way is to multiply two sides-multiply the length and the width. We would write this as ${firstValue} * ${secondValue} = ${solution.solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let fullRows = [];

  for (let i = 0; i < Number(firstValue); i++) {
    columns.push(i);
  }
  for (let i = 0; i < Number(secondValue); i++) {
    fullRows.push(i);
  }

  let secondValueVerticalOffset;
  // I am assuming width = height
  if (secondValue && imgWidth) {
    secondValueVerticalOffset = (imgWidth * secondValue) / 2;
  }

  // and now for the animations and CSS classes
  // function previousVisual() {
  //   if (animationStage > 0) {
  //     setAnimationStage(animationStage - 1);
  //   }
  // }

  // function nextVisual() {
  //   if (animationStage < 3) {
  //     setAnimationStage(animationStage + 1);
  //   }
  // }
  let rowClasses = `${classes.row}`;
  if (animationStage > 0) {
    rowClasses += ` ${classes.rowGroups}`;
  }
  let columnClasses = `${classes.columnClasses} `;
  let topColumnClasses = `${classes.topColumnClasses} `;
  let bottomColumnClasses = `${classes.bottomColumnClasses} `;

  // let previousButtonClasses = `${classes.stageButton}`;
  // if (animationStage === 0) {
  //   previousButtonClasses += ` ${classes.disabledButton}`;
  // }
  // let nextButtonClasses = `${classes.stageButton}`;

  // if (animationStage === 3) {
  //   nextButtonClasses += ` ${classes.disabledButton}`;
  // }

  return (
    <div className={classes.wrapper}>
      <AnimationStage></AnimationStage>
      <br />

      <Instructions text={animationStageArray[animationStage]?.text} />
      {animationStage >= 0 && (
        <p style={{ fontSize: `${Math.max(additionDiagramFontSize, 16)}px` }}>
          {firstValue}
        </p>
      )}
      <div className={classes.arrayWrapper} ref={arrayHeight}>
        {animationStage >= 0 &&
          fullRows.map(function (e) {
            return (
              <div
                className={rowClasses}
                key={`row${e.toString()}`}
                style={{ height: `${imgWidth}px` }}
              >
                {columns.map(function (f) {
                  return (
                    <div
                      key={`${f.toString()}${e.toString()}`}
                      style={{ width: `${imgWidth}px` }}
                      className={
                        e === 0
                          ? topColumnClasses
                          : e === secondValue - 1
                          ? bottomColumnClasses
                          : columnClasses
                      }
                    ></div>
                    // <h2>teddy bear</h2>
                  );
                })}
                {/* only show the addition if the font size is greater than 5. */}
                {additionDiagramFontSize > 7 &&
                  animationStage > 0 &&
                  animationStage < 3 && (
                    <div
                      className={classes.addingRowDiagram}
                      style={{
                        fontSize: `${additionDiagramFontSize}px`,
                        left: `${addingRowDiagramLeftShift}px`,
                        top: `${addingRowDiagramTopShift}px`,
                      }}
                      key={`${e.toString()}RowDiagram`}
                    >
                      {e === 0 && (
                        <p>
                          <span className={classes.firstPlus}>+</span>
                          {firstValue}
                        </p>
                      )}
                      {e > 0 && <p>+{firstValue}</p>}

                      {/* Notice we are taking 2 away because e starts at 0 instead of 1 and then the first row is taken away. */}
                      {e === secondValue - 1 && (
                        <p className={classes.addingRowDiagramSolution}>
                          {solution.solution}
                        </p>
                      )}
                    </div>
                  )}
                {animationStage === 3 &&
                  e === Math.floor(columns.length / 2) && (
                    <div className={classes.multiplicationRightContainer}>
                      <p
                        className={classes.multiplicationRight}
                        style={{ fontSize: `${additionDiagramFontSize}px` }}
                      >
                        {firstValue}
                        <br />
                        <span className={classes.underlined}>
                          {" "}
                          x{secondValue}
                        </span>{" "}
                        <br />
                        {solution.solution}
                      </p>
                    </div>
                  )}
              </div>
            );
          })}

        <p
          className={classes.secondValue}
          style={{
            top: `${secondValueVerticalOffset}px`,
            fontSize: `${Math.max(additionDiagramFontSize, 16)}px`,
          }}
        >
          {animationStage > 0 && secondValue}
        </p>
      </div>
    </div>
  );
}

export default MultiplicationArray;
