import classes from "./PlaceValueChartDisks.module.css";
import { useState, useEffect, useRef } from "react";
import {
  expandedForm,
  regroupedAddition,
  calculateInitialRegroupingAddition,
  getDigitModulusAddition,
  NumberTo4DigitStringWithZeros,
  doAdditionalRegroupingNecessary,
  checkIfAnimationNecessary,
  createDisks,
  renderDiskContainersSmall,
  addPlaceValue,
  calculateDisksByStageAndRowAddition,
  truncateCalculatedDisks,
} from "../../../../javascript/helperFunctions";
import { getAnimationStageArray } from "../../../../javascript/stepInstructionText";
import AnimationStage from "../../AnimationStage";
import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";
import Instructions from "../../Instructions";

function PlaceValueChartDisks() {
  const [state, dispatch] = useCalculatorState();
  const animateColumn = state.animateColumn;
  const { firstValue, secondValue } = state.problem;
  const { solution } = state.problem;
  const animationStage = state.animationStage;
  const solutionString4Characters = NumberTo4DigitStringWithZeros(solution);

  let firstValueExpandedForm = expandedForm(firstValue);
  let secondValueExpandedForm = expandedForm(secondValue);
  const [screenSize, setScreenSize] = useState();

  // This is for the animation states: This is the current row that is being  added for each column.
  // rows lower than this value should be invisible if animation stage ===4 and animationCol >=index.
  // only one row is necessary for addition so I don't think I actually need these regroup animation cols.
  const [regroupAnimationCol2, setRegroupAnimationCol2] = useState(0);
  const [regroupAnimationCol1, setRegroupAnimationCol1] = useState(0);
  const [regroupAnimationCol0, setRegroupAnimationCol0] = useState(0);
  const [allCalculatedDisks, setAllCalculatedDisks] = useState(0);

  const [rectangleWidth, setRectangleWidth] = useState();
  const [rectangleHeight, setRectangleHeight] = useState();
  const [firstValueDigits, setFirstValueDigits] = useState(0);
  const [secondValueDigits, setSecondValueDigits] = useState(0);
  const [columns, setColumns] = useState([0, 1, 2, 3]);

  useEffect(() => {
    const untruncatedDisks = calculateDisksByStageAndRowAddition(
      firstValue,
      secondValue
    );
    let biggerValueDigits = Math.max(firstValueDigits, secondValueDigits);

    const truncatedDisks = truncateCalculatedDisks(
      untruncatedDisks,
      4 - biggerValueDigits - 1
    );
    setAllCalculatedDisks(truncatedDisks);
  }, [firstValue, secondValue, firstValueDigits, secondValueDigits]);

  // This function will shorten the arrays of disks as necessary (if we are solving 2+3, we only need ones and tens)

  // This function uses the allCalculatedDisks object to return jsx code for the current row/column.
  function fillCell(
    column,
    row,
    allCalculatedDisks,
    animationStage,
    diskDiameter = "20px"
  ) {
    try {
      if (row < 2) {
        let currentStage = `animationStage${animationStage}`;
        let currentRow = `row${row}`;
        const numberOfCircles =
          allCalculatedDisks[currentStage][currentRow][column];
        const arr = new Array(numberOfCircles).fill(3);

        // calculate if we need an animation check (eg animation stage 4)

        const { styles, animatingClasses } = checkIfAnimationNecessary(
          column,
          numberOfCircles,
          row,
          columns,
          rows,
          animateColumn,
          rectangleWidth,
          rectangleHeight,
          animationStage,
          regroupAnimationCol0,
          regroupAnimationCol1,
          regroupAnimationCol2,
          classes
        );
        let checkDiskAppear = "";
        // in stage 4, we add a circle that gradually appears along with transition animation for regrouping.
        if (animationStage === 4 && row === 1) {
          if (column === 0 && regroupedIntoCol0) {
            checkDiskAppear = classes.diskHide;
          }
          if (column === 1 && regroupedIntoCol1) {
            checkDiskAppear = classes.diskHide;
          }
          if (column === 2 && regroupedIntoCol2) {
            checkDiskAppear = classes.diskHide;
          }
          // now check for animateColumn, which is the context that is being updated by setTimeout.
          if (column === 0 && regroupedIntoCol0 && animateColumn <= 0) {
            checkDiskAppear = classes.diskAppear;
          }
          if (column === 1 && regroupedIntoCol1 && animateColumn <= 1) {
            checkDiskAppear = classes.diskAppear;
          }
          if (column === 2 && regroupedIntoCol2 && animateColumn <= 2) {
            checkDiskAppear = classes.diskAppear;
          }
        }

        let jsxValues = arr.map((_, index) => {
          return (
            <div
              key={`row:${row}column:${column}index:${index}`}
              // if we are at the last circle, then we might need to add the fade in animation for stage4
              className={`${classes.disk} ${
                index === arr.length - 1 ? checkDiskAppear : ""
              }`}
              style={{
                width: diskDiameter,
                height: diskDiameter,
              }}
            ></div>
          );
        });
        return (
          <div
            className={`${classes.diskContainer} ${animatingClasses}`}
            style={{ ...styles }}
          >
            {jsxValues}
          </div>
        );
      }
    } catch (e) {
      console.log("error in fillCellFunction. ", e);
      return;
    }
  }

  useEffect(() => {
    // set the stage
    dispatch({ type: "SET_MAX_STAGE", value: 4 });
    dispatch({ type: "RESET_CURRENT_STAGE" });
    if (parseInt(firstValue) >= 1000) {
      console.log("the first number is too big to show in our diagrams.");
    } else if (parseInt(firstValue) >= 100) {
      setFirstValueDigits(3);
    } else if (parseInt(firstValue) >= 10) {
      setFirstValueDigits(2);
    } else if (parseInt(firstValue) >= 1) {
      setFirstValueDigits(1);
    }
    if (parseInt(secondValue) >= 1000) {
      // Fix me-post error.
      console.log("the second number is too big to show in our diagrams.");
    } else if (parseInt(secondValue) >= 100) {
      setSecondValueDigits(3);
    } else if (parseInt(secondValue) >= 10) {
      setSecondValueDigits(2);
    } else if (parseInt(secondValue) >= 1) {
      setSecondValueDigits(1);
    }
  }, [firstValue, secondValue, dispatch]);

  const biggerValueDigits = Math.max(firstValueDigits, secondValueDigits);

  // if the biggest number of digits is 3, then 4-3-1=0. In other words, we won't change the length of our calculateddisks array.
  // if the biggest number is 2, then 4-2-1=1. So we will cut it short by 1.

  // Note-I am writing this to work with up to 3 digit numbers.

  // this is for the amount that we regroup:
  let firstValueString = firstValue.toString();
  let secondValueString = secondValue.toString();

  // let regroupedIntoCol2 = 0;
  // This is the total number that will be sent into the named column during regrouping.
  // this will be either 0 or 1. It is set using calculateInitialRegroupingAddition.
  let regroupedIntoCol1 = 0;
  let regroupedIntoCol0 = 0;
  let regroupedIntoCol2 = 0;
  // ReversedIndexOfPlaceValue starts at -1 and goes to -2, -3

  // index is the value you will be multiplying by second value to see what is left over in the ones place.

  if (biggerValueDigits === 3) {
    regroupedIntoCol2 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -1
    );
    regroupedIntoCol1 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -2
    );
    // Now if we regrouped into col2, then we need to check to see if that adds to col1:
    // To do that, we will add regroupedIntoCol 2 to the 2nd digit of the firstValue * the 2nd value, divided by 10.

    if (regroupedIntoCol2 > 0) {
      regroupedIntoCol1 = doAdditionalRegroupingNecessary(
        regroupedIntoCol2,
        regroupedIntoCol1,
        getDigitModulusAddition(firstValueString, secondValueString, 1)
      );
    }
    regroupedIntoCol0 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -3
    );
    if (regroupedIntoCol1 > 0) {
      regroupedIntoCol0 = doAdditionalRegroupingNecessary(
        regroupedIntoCol1,
        regroupedIntoCol0,
        getDigitModulusAddition(firstValueString, secondValueString, 0)
      );
    }
  } else if (biggerValueDigits === 2) {
    regroupedIntoCol1 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -1
    );
    regroupedIntoCol0 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -2
    );
    if (regroupedIntoCol1 > 0) {
      regroupedIntoCol0 = doAdditionalRegroupingNecessary(
        regroupedIntoCol1,
        regroupedIntoCol0,
        getDigitModulusAddition(firstValueString, secondValueString, 0)
      );
    }
  } else if (biggerValueDigits === 1) {
    regroupedIntoCol0 = calculateInitialRegroupingAddition(
      firstValueString,
      secondValueString,
      -1
    );
  }

  // FIX ME: This doesn't work with numbers that start with 01 x10 for example.

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize) {
      setRectangleHeight(screenSize.height / 3);
      setRectangleWidth(Math.max((screenSize.width * 6) / 7, 300));
    }
  }, [screenSize]);

  // set the number of columns
  useEffect(() => {
    let placeValuesWords = ["thousands", "hundreds", "tens", "ones"];

    if (Number(firstValue) > Number(secondValue)) {
      const offset = placeValuesWords.length - (firstValueDigits + 1);

      const newArray1 = placeValuesWords.slice(offset);

      setColumns(newArray1);
    } else {
      const offset = placeValuesWords.length - (secondValueDigits + 1);

      const newArray2 = placeValuesWords.slice(offset);
      console.log("test3.2", newArray2);

      setColumns(newArray2);
    }
  }, [firstValue, secondValue, firstValueDigits, secondValueDigits]);
  let currentAddValue = 0;

  useEffect(() => {
    let timeoutId;

    if (animationStage === 4) {
      // I hard coded 9 because I don't know how to access the number of divs.
      // if (allCalculatedDisks.animationStage4.row0.splice(-1) === 10) {
      //   // fix me: I am working here.
      // }

      if (animateColumn === 3) {
        if (regroupAnimationCol2 < regroupedIntoCol2) {
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol2((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      } else if (animateColumn === 2) {
        if (regroupAnimationCol1 < regroupedIntoCol1) {
          // If all regroupAnimationCol2 operations are done
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol1((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      } else if (animateColumn === 1) {
        if (regroupAnimationCol0 < regroupedIntoCol0) {
          // If all regroupAnimationCol1 operations are done
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol0((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      }
    } else {
      setRegroupAnimationCol2(0); // reset when animationStage is not 4
      setRegroupAnimationCol1(0); // reset when animationStage is not 4
      setRegroupAnimationCol0(0); // reset when animationStage is not 4
    }

    return () => clearTimeout(timeoutId); // cleanup on unmount
  }, [
    animationStage,
    regroupAnimationCol2,
    regroupAnimationCol1,
    regroupedIntoCol2,
    regroupedIntoCol1,
    currentAddValue,
    animateColumn,
    regroupedIntoCol0,
    regroupAnimationCol0,
  ]); // re-run effect when animationStage or activeDivIndex changes

  const regroupedObject = regroupedAddition(firstValue, secondValue);

  const animationStageArray = getAnimationStageArray(
    firstValue,
    secondValue,
    firstValueExpandedForm,
    secondValueExpandedForm,
    solution,
    regroupedObject
  );

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let rows = [0, 1, 2];

  // render circles based off of column and row:
  // to extract this function, i need to add animationStage, classes,
  function placeDisks(numberOfDisks, screenWidth, rowNumber, colNumber) {
    // note: no row should have more than 10 disks
    if (numberOfDisks > 10) {
      numberOfDisks = 10;
    }

    let diskDiameter = "20px";
    const breakPointSm = 570;
    const breakPointMd = 1000;
    let borderCheck;

    if (animationStage === 4 && numberOfDisks >= 10) {
      borderCheck = `${classes.diskBorder} ${classes.diskContainerAnimation}`;
    } else {
      borderCheck = "";
    }

    if (screenWidth < breakPointSm) {
      diskDiameter = "13px";
    }
    // if number of disks is invalid, return.
    if (!(numberOfDisks > -1)) {
      console.log("Number of disks is invalid in place disks function");
    }

    const { styles, animatingClasses } = checkIfAnimationNecessary(
      colNumber,
      numberOfDisks,
      rowNumber,
      columns,
      rows,
      animateColumn,
      rectangleWidth,
      rectangleHeight,
      animationStage,
      regroupAnimationCol0,
      regroupAnimationCol1,
      regroupAnimationCol2,
      classes
    );

    if (screenWidth > breakPointMd) {
      return (
        <div
          className={`${classes.diskContainer} ${animatingClasses} `}
          style={styles}
        >
          {createDisks(numberOfDisks, diskDiameter, classes)}
        </div>
      );
    } else {
      // this is for smaller and medium screens-make 2 rows of up to 5 disks
      return renderDiskContainersSmall(
        numberOfDisks,
        diskDiameter,
        styles,
        borderCheck,
        classes
      );
    }
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <AnimationStage />
        <br />
        <Instructions text={animationStageArray[animationStage]?.text} />
      </div>
      <p style={{ fontSize: `${16}px` }}></p>

      <div
        className={classes.arrayWrapper}
        ref={arrayHeight}
        style={{
          minHeight: `${rectangleHeight}px`,
          width: `${rectangleWidth}px`,
        }}
      >
        {columns.map(function (e, index) {
          return (
            <div
              className={`${classes[`column-${index}`]} ${classes.allColumns}`}
              key={`column${index.toString()}`}
              style={{
                width: `${rectangleWidth / columns.length}px`,
              }}
            >
              {/* we offset the top thousands/hundreds for mobile view for spacing */}

              <div
                className={
                  index % 2 === 0
                    ? classes.expandedFormTop
                    : classes.expandedFormTopOffset
                }
                style={{ width: `${rectangleWidth / columns.length}px` }}
              >
                <span>{columns[index]}</span>
              </div>

              {/* This is the first row-in other words, the input should be the first  */}
              {rows.map(function (f, index2) {
                return (
                  <div
                    className={`${classes[`row-${index2}`]} ${classes.allRows}`}
                    key={`row${index2.toString()}col${index.toString()}`}
                    style={{
                      height: `${Math.max(
                        rectangleHeight / rows.length,
                        50
                      )}px`,
                      borderTopWidth:
                        index2 === rows.length - 1 ? "5px" : "2px",
                    }}
                  >
                    {/* render the firstValue and SecondValue to the left */}
                    {animationStage === 2 && index2 === 0 && index === 0 && (
                      <p
                        className={classes.leftValues}
                        key={`column${index.toString()}valuestoleft`}
                      >
                        {firstValue}
                      </p>
                    )}
                    {animationStage === 2 && index2 === 1 && index === 0 && (
                      <p
                        className={classes.leftValues}
                        key={`column${index.toString()}valuestoleft`}
                      >
                        +{secondValue}
                      </p>
                    )}

                    {/* this is the top row, rendered first. */}

                    {fillCell(
                      index,
                      index2,
                      allCalculatedDisks,
                      animationStage
                    )}

                    {/* animation stage 4 (step 5) is below. This involves translations. The same disks start present, but they will fade away.*/}

                    {/* This is the solution row: (but not regrouping*/}

                    {animationStage > 1 &&
                      animationStage < 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {addPlaceValue(
                            firstValueString,
                            secondValueString,
                            index,
                            columns
                          )}
                        </p>
                      )}

                    {/* This is the solution row: expanded form (but not regrouping*/}

                    {animationStage === 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {expandedForm(
                            addPlaceValue(
                              firstValueString,
                              secondValueString,
                              index,
                              columns
                            )
                          ).join("+")}
                        </p>
                      )}

                    {/* this is the total text off to the left */}
                    {animationStage > 1 &&
                      index === 0 &&
                      index2 === rows.length - 1 &&
                      screenSize.width > 500 && (
                        <p
                          className={classes.totalLabel}
                          style={{
                            fontSize: `${16}px`,
                            // top: rectangleHeight / 3,
                          }}
                        >
                          Total
                        </p>
                      )}

                    {/* this is the regrouping values for thousands */}
                    {animationStage === 4 && index2 === rows.length - 1 && (
                      <p>
                        {
                          allCalculatedDisks[`animationStage${animationStage}`][
                            `row2`
                          ][index]
                        }
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlaceValueChartDisks;
