import classes from "./Solution.module.css";
import { useCalculatorState } from "../../../contexts/CalculatorStateProvider";
function Solution() {
  const [state, dispatch] = useCalculatorState();
  const solution = state.problem;
  return (
    <div className={classes.wrapper}>
      <h2>Solution</h2>
      {solution.solution && (
        <p>
          {solution.query} = {solution.solution}
        </p>
      )}
    </div>
  );
}
export default Solution;
