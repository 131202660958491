import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CalculatorPage from "./pages/calculator/index";
import HomePage from "./pages/index.js";
import { ModalContextProvider } from "./contexts/ModalContextProvider";
import NotFound from "./pages/NotFound.js";
import Stories from "./pages/stories/index.js";
import ListStories from "./pages/stories/list/ListStories.js";
import EditStory from "./pages/stories/edit/EditStory.js";
import ShowStory from "./pages/stories/show/ShowStory.js";
function App() {
  return (
    <ModalContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calculator" element={<CalculatorPage />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/stories/list" element={<ListStories />} />
          <Route path="/stories/edit" element={<EditStory />} />
          <Route
            path="/stories/early-readers/:storyId"
            element={<ShowStory />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ModalContextProvider>
  );
}
export default App;
