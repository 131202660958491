import allMultiplicationStrategies from "./allMultiplicationStrategies";
export default function chooseMultiplicationMethod(firstValue, secondValue) {
  const allOptions = allMultiplicationStrategies;

  let validOptions;
  let bestOption;
  // check if numbers are acceptable:
  if (firstValue >= 1000 || secondValue >= 1000) {
    validOptions = [];
    bestOption = null;
    return { validOptions, bestOption };
  } else if (firstValue > 50 || secondValue > 50) {
    validOptions = [allOptions[2], allOptions[3], allOptions[4]];
    bestOption = allOptions[2];
  } else if (firstValue > 9 || secondValue > 9) {
    validOptions = [allOptions[1], allOptions[2], allOptions[3], allOptions[4]];
    if (firstValue > 20 || secondValue > 20) {
      bestOption = allOptions[2];
    } else {
      bestOption = allOptions[1];
    }
  } else {
    validOptions = allOptions;
    bestOption = allOptions[0];
  }
  // now add place value on a certain condition:
  if (
    (firstValue > 10 && secondValue > 10) ||
    firstValue > 1000 ||
    secondValue > 1000
  ) {
    validOptions.pop();
    validOptions.pop();
  } else {
    // make the bestOption the placeValueChartDisks if either value is greater than 10:
    if (firstValue > 10 || secondValue > 10) {
      bestOption = allOptions[3];
    }
  }

  return { validOptions, bestOption };
}
