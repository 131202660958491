import allAdditionStrategies from "./allAditionStrategies";
export default function chooseMultiplicationMethod(firstValue, secondValue) {
  let validOptions;
  let bestOption;
  const allOptions = allAdditionStrategies;
  if (firstValue >= 1000 || secondValue >= 1000) {
    validOptions = [];
    bestOption = null;
    return { validOptions, bestOption };
  }
  validOptions = allOptions;
  bestOption = allAdditionStrategies[0];
  // check if numbers are acceptable:

  return { validOptions, bestOption };
}
