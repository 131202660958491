import classes from "./PlaceValueChartDisks.module.css";
import { useState, useEffect, useRef } from "react";
import {
  expandedForm,
  regroupedMultiplication,
} from "../../../../javascript/helperFunctions";
import AnimationStage from "../../AnimationStage";
import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";
import Instructions from "../../Instructions";

function PlaceValueChartDisks() {
  const [state, dispatch] = useCalculatorState();
  const animateColumn = state.animateColumn;
  let { firstValue, secondValue, query } = state.problem;
  const { solution } = state.problem;
  const animationStage = state.animationStage;

  // let { solution, , firstValue, secondValue } = props.data;
  if (parseFloat(secondValue) > parseFloat(firstValue)) {
    const temp = firstValue;
    firstValue = secondValue;
    secondValue = temp;
  }

  let firstValueExpandedForm = expandedForm(firstValue);
  let secondValueExpandedForm = expandedForm(secondValue);
  const [screenSize, setScreenSize] = useState();

  // This is for the animation states: This is the current row that is being  added for each column.
  // rows lower than this value should be invisible if animation stage ===4 and animationCol >=index.
  const [regroupAnimationCol2, setRegroupAnimationCol2] = useState(0);
  const [regroupAnimationCol1, setRegroupAnimationCol1] = useState(0);
  const [regroupAnimationCol0, setRegroupAnimationCol0] = useState(0);

  // These states track whether

  const [rectangleWidth, setRectangleWidth] = useState();
  const [rectangleHeight, setRectangleHeight] = useState();
  const [firstValueDigits, setFirstValueDigits] = useState(0);
  const [secondValueDigits, setSecondValueDigits] = useState(0);

  // this is for regrouping:
  let rowToAddDisksTens = 0;
  let rowToAddDisksHundreds = 0;

  // const [animationStage, setAnimationStage] = useState(0);
  // Note-I am writing this to work with up to 3 digit numbers.

  // this is for the amount that we regroup:
  let firstValueString = firstValue.toString();

  // let regroupedIntoCol2 = 0;
  // This is the total number that will be sent into the named column during regrouping.
  let regroupedIntoCol1 = 0;
  let regroupedIntoCol0 = 0;
  let regroupedIntoCol2 = 0;
  // ReversedIndexOfPlaceValue starts at -1 and goes to -2, -3
  function calculateInitialRegrouping(valueString, reversedIndexOfPlaceValue) {
    return Math.floor(
      (valueString[valueString.length + reversedIndexOfPlaceValue] *
        secondValue) /
        10
    );
  }
  // index is the value you will be multiplying by second value to see what is left over in the ones place.
  function getDigitModulus(str, secondValue, index) {
    return (Number(str[index]) * secondValue) % 10;
  }

  function doAdditionalRegroupingNecessary(
    prevRegroup,
    curRegroup,
    previousDigitModulus
  ) {
    curRegroup += Math.floor((prevRegroup + previousDigitModulus) / 10);
    return curRegroup;
  }
  if (firstValueDigits === 3) {
    regroupedIntoCol2 = calculateInitialRegrouping(firstValueString, -1);
    regroupedIntoCol1 = calculateInitialRegrouping(firstValueString, -2);
    // Now if we regrouped into col2, then we need to check to see if that adds to col1:
    // To do that, we will add regroupedIntoCol 2 to the 2nd digit of the firstValue * the 2nd value, divided by 10.
    if (regroupedIntoCol2 > 0) {
      regroupedIntoCol1 = doAdditionalRegroupingNecessary(
        regroupedIntoCol2,
        regroupedIntoCol1,
        getDigitModulus(firstValueString, secondValue, 1)
      );
    }
    regroupedIntoCol0 = calculateInitialRegrouping(firstValueString, -3);
    if (regroupedIntoCol1 > 0) {
      regroupedIntoCol0 = doAdditionalRegroupingNecessary(
        regroupedIntoCol1,
        regroupedIntoCol0,
        getDigitModulus(firstValueString, secondValue, 0)
      );
    }
  } else if (firstValueDigits === 2) {
    regroupedIntoCol1 = calculateInitialRegrouping(firstValueString, -1);
    regroupedIntoCol0 = calculateInitialRegrouping(firstValueString, -2);
    if (regroupedIntoCol1 > 0) {
      regroupedIntoCol0 = doAdditionalRegroupingNecessary(
        regroupedIntoCol1,
        regroupedIntoCol0,
        getDigitModulus(firstValueString, secondValue, 0)
      );
    }
  } else if (firstValueDigits === 1) {
    regroupedIntoCol0 = calculateInitialRegrouping(firstValueString, -1);
  }

  // FIX ME: This doesn't work with numbers that start with 01 x10 for example.

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize) {
      setRectangleHeight(screenSize.height / 3);
      setRectangleWidth(Math.max((screenSize.width * 6) / 7, 280));
    }
  }, [screenSize]);

  useEffect(() => {
    dispatch({ type: "SET_MAX_STAGE", value: 4 });
    dispatch({ type: "RESET_CURRENT_STAGE" });
    if (parseInt(firstValue) >= 1000) {
      console.log("the first number is too big to show in our diagrams.");
    } else if (parseInt(firstValue) >= 100) {
      setFirstValueDigits(3);
    } else if (parseInt(firstValue) >= 10) {
      setFirstValueDigits(2);
    } else if (parseInt(firstValue) >= 1) {
      setFirstValueDigits(1);
    }
    if (parseInt(secondValue) >= 1000) {
      // Fix me-post error.
      console.log("the second number is too big to show in our diagrams.");
    } else if (parseInt(secondValue) >= 100) {
      setSecondValueDigits(3);
    } else if (parseInt(secondValue) >= 10) {
      setSecondValueDigits(2);
    } else if (parseInt(secondValue) >= 1) {
      setSecondValueDigits(1);
    }
  }, [firstValue, secondValue]);

  let currentAddValue = 0;

  useEffect(() => {
    let timeoutId;

    if (animationStage === 4) {
      // I hard coded 9 because I don't know how to access the number of divs.
      if (animateColumn === 3) {
        if (regroupAnimationCol2 < regroupedIntoCol2) {
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol2((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      } else if (animateColumn === 2) {
        if (regroupAnimationCol1 < regroupedIntoCol1) {
          // If all regroupAnimationCol2 operations are done
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol1((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      } else if (animateColumn === 1) {
        if (regroupAnimationCol0 < regroupedIntoCol0) {
          // If all regroupAnimationCol1 operations are done
          timeoutId = setTimeout(() => {
            setRegroupAnimationCol0((prevIndex) => prevIndex + 1);
          }, 1000); // change div every 1 second
        } else {
          dispatch({ type: "ANIMATE_COLUMN" });
        }
      }
    } else {
      setRegroupAnimationCol2(0); // reset when animationStage is not 4
      setRegroupAnimationCol1(0); // reset when animationStage is not 4
      setRegroupAnimationCol0(0); // reset when animationStage is not 4
    }

    return () => clearTimeout(timeoutId); // cleanup on unmount
  }, [
    animationStage,
    regroupAnimationCol2,
    regroupAnimationCol1,
    regroupedIntoCol2,
    regroupedIntoCol1,
    currentAddValue,
    animateColumn,
    regroupedIntoCol0,
    regroupAnimationCol0,
  ]); // re-run effect when animationStage or activeDivIndex changes

  const regroupedObject = regroupedMultiplication(firstValue, secondValue);

  const animationStageArray = [
    {
      text: `To solve ${query}, first we want to place the larger number on the place value chart. Notice that this chart is broken into columns representing the place value of each digit--reading from right to left, we have the ones${
        firstValueDigits > 2
          ? ", tens, hundreds, and thousands"
          : firstValueDigits > 1
          ? ", tens, and hundreds"
          : " and tens"
      }. We will represent the larger number using disks. How many disks do you think will go in the ones place?`,
    },
    {
      text: `To show the larger number on this place value chart,  we want to use Expanded Form.  Expanded Form is when you break a number into digits--into ones, tens, hundreds, and so on. We can write ${firstValue} as ${firstValueExpandedForm.join(
        "+"
      )}. ${
        firstValueDigits < 2
          ? `Because ${firstValue} only has one digit, the Expanded Form looks the same as the normal form. `
          : ""
      }Imagine you were going to add 40 to the place value chart. We would turn 40 into 4 tens, and put 4 disks in the tens column.  We have added the larger value to the place value chart. How do you think we will multiply in the next step?`,
    },
    {
      text: `Multiplication is the same thing as repeated addition. So for each column, we can add the same number of disks multiple times, until we have ${secondValue} sets of disks. We add those values at the bottom, and we are almost at our answer. There might be another step though.`,
    },
    {
      text: `There is one important rule with a place value chart: Your final answer should never have more than 10 in a place value column. 10 ones is the same as 1 ten. 10 tens is the same as 1 hundred, and 10 hundreds is the same as 1 thousand.  If we do have more than 10 in any place value column in our answer, then we need to regroup. Below, you will see the same number of disks in each column, but to prepare for regrouping, we reorganized the disks into groups of ten if possible.`,
    },
    {
      text: `If there are more than 10 disks in a column, then those disks should be moving over to the column to the left. 10 ones would become 1 ten, 10 tens would become one hundred, and so on.  This process is called regrouping.  ${
        regroupedObject
          ? regroupedObject.changed
            ? `In this case, you will notice that we did regroup the ${
                regroupedObject.onesChanged ? "ones, and " : ""
              } ${regroupedObject.tensChanged ? "tens, and " : ""} ${
                regroupedObject.hundredsChanged ? "hundreds, and " : ""
              }these changes are shown below.`
            : `In this case, we did not have to regroup.`
          : ""
      } The final answer, as seen below, is ${solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let placeValuesWords = ["thousands", "hundreds", "tens", "ones"];

  let rows = [];
  if (firstValueDigits > placeValuesWords.length || firstValueDigits < 1) {
    // handle error here
    console.log("Either a value was too big, or a value is missing.");
  } else {
    columns = placeValuesWords.slice(-(firstValueDigits + 1));
  }
  // for (let i = 0; i < firstValueDigits; i++) {
  //   columns.push(i);
  // }
  // Notice i <= second value-we want one extra row for the solution row.
  for (let i = 0; i <= secondValue; i++) {
    rows.push(i);
  }

  function getNumberOfDisks(index) {
    if (index === 0) {
      return 0;
    }
    if (index > firstValueExpandedForm.length) {
      return 0;
    }
    return Number(firstValueExpandedForm[index - 1].toString().slice(0, 1));
  }
  // this function returns an array with 2 values: the 10s and the 1s for a given place value.
  function getGroupsOfTen(numberOfDisks) {
    // let totalAmountInRow = numberOfDisks * secondValue;
    return [Math.floor(numberOfDisks / 10), numberOfDisks % 10];
  }

  // render circles based off of digit:
  function placeDisks(numberOfDisks, screenWidth, rowNumber, colNumber) {
    // note: no row should have more than 10 disks
    if (numberOfDisks > 10) {
      numberOfDisks = 10;
    }

    if (colNumber === 1 && numberOfDisks < 10) {
      // Set the hundreds place state
      rowToAddDisksHundreds = rowNumber;
    }
    if (colNumber === 2 && numberOfDisks < 10) {
      // Set the tens place state
      rowToAddDisksTens = rowNumber;
    }

    let diskDiameter = "20px";
    const breakPointSm = 570;
    const breakPointMd = 1000;
    let borderCheck;
    let addToThisRow = 0;
    // first calculate the size of each row, and then multiply by rectangle height
    // This is the incomplete row, so this is where we add the moving pieces.
    // FIX ME-I'm surprised this works with fewer than 4 columns...
    if (colNumber === 3) {
      addToThisRow = rowToAddDisksTens;
    } else if (colNumber === 2) {
      addToThisRow = rowToAddDisksHundreds;
    } else {
      addToThisRow = 0;
    }
    // note we add back the addToThisRow * rows.length
    const translateY = `${
      Math.max(rectangleHeight / rows.length, 50) * (addToThisRow - rowNumber)
    }px`;

    let animationCheck;
    // leave this out if we are in the fisrt column
    if (colNumber !== 0 && numberOfDisks >= 10 && animationStage === 4) {
      // set the outside varirable counter so we know how many we will add total.
      // if (colNumber === 3) {
      //   regroupedIntoCol2++;
      //   console.log("updated regrouped into col2", regroupedIntoCol2);
      // } else if (colNumber === 2) {
      //   regroupedIntoCol1++;
      // } else if (colNumber === 1) {
      //   regroupedIntoCol0++;
      // }
      // This code is checking to see how many columns should have the transform applied-eg these are the regrouping columns that will shift. The column 3 would match regroupcolumn2.
      const regroupAmount =
        colNumber === 3
          ? regroupAnimationCol2
          : colNumber === 2
          ? regroupAnimationCol1
          : colNumber === 1
          ? regroupAnimationCol0
          : 0;
      // check to make sure it is this column's time to move (or be disappeared)
      if (colNumber >= animateColumn) {
        animationCheck = {
          transform:
            rowNumber <= regroupAmount
              ? `translateX(-${
                  rectangleWidth / columns.length
                }px) translateY(${translateY})`
              : "",
          opacity: rowNumber <= regroupAmount ? `0` : "1",
        };
      }
    } else {
      animationCheck = {};
    }

    if (animationStage === 4 && numberOfDisks >= 10) {
      borderCheck = `${classes.diskBorder} ${classes.diskContainerAnimation}`;
    } else {
      borderCheck = "";
    }

    if (screenWidth < breakPointSm) {
      diskDiameter = "13px";
    }
    // if number of disks is invalid, return.
    if (!(numberOfDisks > -1)) {
      return;
    }
    if (screenWidth > breakPointMd) {
      return (
        <div
          className={`${classes.diskContainer} ${borderCheck} ${animationCheck}`}
          style={animationCheck}
        >
          {Array.from({ length: numberOfDisks }, (_, i) => (
            <div
              key={i}
              className={classes.disk}
              style={{ width: diskDiameter, height: diskDiameter }}
            ></div>
          ))}
        </div>
      );
    } else {
      // this is for smaller and medium screens-make 2 rows of up to 5 disks
      if (numberOfDisks > 5) {
        let firstRow = 5;
        let secondRow = numberOfDisks - 5;
        return (
          <div
            className={`${classes.diskContainerWrapper} ${borderCheck} ${animationCheck}`}
            style={animationCheck}
          >
            <div className={classes.diskContainer}>
              {Array.from({ length: firstRow }, (_, i) => (
                <div
                  key={i}
                  className={classes.disk}
                  style={{ width: diskDiameter, height: diskDiameter }}
                ></div>
              ))}
            </div>
            <div className={classes.diskContainer}>
              {Array.from({ length: secondRow }, (_, i) => (
                <div
                  key={i}
                  className={classes.disk}
                  style={{ width: diskDiameter, height: diskDiameter }}
                ></div>
              ))}
            </div>
          </div>
        );
      } else {
        // this is for fewer disks on a small screen
        return (
          <div
            className={`${classes.diskContainer} ${borderCheck} ${animationCheck}`}
            style={animationCheck}
          >
            {Array.from({ length: numberOfDisks }, (_, i) => (
              <div
                key={i}
                className={classes.disk}
                style={{ width: diskDiameter, height: diskDiameter }}
              ></div>
            ))}
          </div>
        );
      }
    }
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <AnimationStage />
        <br />
        <Instructions text={animationStageArray[animationStage]?.text} />
      </div>
      <p style={{ fontSize: `${16}px` }}></p>

      <div
        className={classes.arrayWrapper}
        ref={arrayHeight}
        style={{
          minHeight: `${rectangleHeight}px`,
          width: `${rectangleWidth}px`,
        }}
      >
        {columns.map(function (e, index) {
          return (
            <div
              className={`${classes[`column-${index}`]} ${classes.allColumns}`}
              key={`column${e.toString()}`}
              style={{
                width: `${rectangleWidth / columns.length}px`,
              }}
            >
              {/* we offset the top thousands/hundreds for mobile view for spacing */}
              {animationStage > -1 && (
                <div
                  className={
                    index % 2 === 0
                      ? classes.expandedFormTop
                      : classes.expandedFormTopOffset
                  }
                  style={{ width: `${rectangleWidth / columns.length}px` }}
                >
                  <span>{columns[index]}</span>
                </div>
              )}

              {rows.map(function (f, index2) {
                return (
                  <div
                    className={`${classes[`row-${index2}`]} ${classes.allRows}`}
                    key={`row${f.toString()}col${f.toString}`}
                    style={{
                      height: `${Math.max(
                        rectangleHeight / rows.length,
                        50
                      )}px`,
                      borderTopWidth:
                        index2 === rows.length - 1 ? "5px" : "2px",
                    }}
                  >
                    {/* this is the top row, rendered first. */}
                    {animationStage === 1 &&
                      index > 0 &&
                      index2 === 0 &&
                      placeDisks(
                        getNumberOfDisks(index),
                        screenSize.width,
                        index2,
                        index
                      )}

                    {/* This is the duplicated contents of the firstValue */}
                    {animationStage > 1 &&
                      animationStage < 3 &&
                      index > 0 &&
                      index2 !== rows.length - 1 &&
                      placeDisks(
                        getNumberOfDisks(index),
                        screenSize.width,
                        index2,
                        index
                      )}
                    {/* This is the first step of regrouping disks-only showing the groups of 10 */}
                    {animationStage > 2 &&
                      index > 0 &&
                      index2 !== rows.length - 1 &&
                      index2 <
                        getGroupsOfTen(
                          getNumberOfDisks(index) * secondValue
                        )[0] &&
                      // get groups of 10 returns an array[#of tens, ones] I want to repeat the tens based off of index2
                      placeDisks(10, screenSize.width, index2, index)}

                    {/* This is the second step of regrouping disks-showing the remainder after the groups of 10 */}
                    {/* This is the regrouping row: add the regrouping amount. */}

                    {animationStage > 2 &&
                      index > -1 &&
                      index2 !== rows.length - 1 &&
                      index2 ===
                        getGroupsOfTen(
                          getNumberOfDisks(index) * secondValue
                        )[0] &&
                      // get groups of 10 returns an array[#of tens, ones] I want to repeat the tens based off of index2
                      placeDisks(
                        getGroupsOfTen(
                          getNumberOfDisks(index) * secondValue
                        )[1] +
                          // the index should be 1 less than the animate column for it to first appear.
                          (index >= animateColumn - 1 && index === 2
                            ? regroupAnimationCol2
                            : index >= animateColumn - 1 && index === 1
                            ? regroupAnimationCol1
                            : index >= animateColumn - 1 && index === 0
                            ? regroupAnimationCol0
                            : 0),

                        screenSize.width,
                        index2,
                        index
                      )}
                    {/* This row is tricky: Imagine you are regrouping from ones to tens, and the current row of 10s fills up. Then you need to take the excess disks and put them on a new row. 
                      -First, check to make sure we are in the row that is 1 more than the number of 10s (I think using get groups of Ten).
                      -Then, check to see if the modulus amount (eg ones place in this column) plus the regrouping exceeds 10.
                      -if it does, then this row (the following row) will have currAmount - 10 disks in it.
                      */}
                    {animationStage > 2 &&
                      index > 0 &&
                      index2 !== rows.length - 1 &&
                      index2 ===
                        getGroupsOfTen(
                          getNumberOfDisks(index) * secondValue
                        )[0] +
                          1 && (
                        <p>
                          {getGroupsOfTen(
                            getNumberOfDisks(index + 1) * secondValue
                          )[0] +
                            getGroupsOfTen(
                              getNumberOfDisks(index) * secondValue
                            )[1]}
                        </p>
                      ) &&
                      // We are just placing the disks over 10 into the next row from the regrouping.  Most times, this won't happen.
                      placeDisks(
                        0 +
                          // the index should be 1 less than the animate column for it to first appear.
                          // the amount that we are showing is this col ones  + regroupAnimationCol -10
                          (index >= animateColumn - 1 && index === 2
                            ? getGroupsOfTen(
                                getNumberOfDisks(index) * secondValue
                              )[1] +
                              regroupAnimationCol2 -
                              10
                            : index >= animateColumn - 1 && index === 1
                            ? getGroupsOfTen(
                                getNumberOfDisks(index) * secondValue
                              )[1] +
                              regroupAnimationCol1 -
                              10
                            : index >= animateColumn - 1 && index === 0
                            ? getGroupsOfTen(
                                getNumberOfDisks(index) * secondValue
                              )[1] +
                              regroupAnimationCol0 -
                              10
                            : 0),

                        screenSize.width,
                        index2,
                        index
                      )}

                    {/* animation stage 4 (step 5) is below. This involves translations. The same disks start present, but they will fade away.*/}

                    {/* This is the solution row: (but not regrouping*/}

                    {animationStage > 1 &&
                      animationStage < 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {firstValueExpandedForm[index - 1]
                            .toString()
                            .slice(0, 1) * secondValue}
                        </p>
                      )}

                    {/* This is the solution row: expanded form (but not regrouping*/}

                    {animationStage === 3 &&
                      index > 0 &&
                      index2 === rows.length - 1 && (
                        <p>
                          {expandedForm(
                            firstValueExpandedForm[index - 1]
                              .toString()
                              .slice(0, 1) * secondValue
                          ).join("+")}
                        </p>
                      )}

                    {/* this is the total text off to the left */}
                    {animationStage > 1 &&
                      index === 0 &&
                      index2 === rows.length - 1 &&
                      screenSize.width > 600 && (
                        <p
                          className={classes.totalLabel}
                          style={{
                            fontSize: `${16}px`,
                            // top: rectangleHeight / 3,
                          }}
                        >
                          Total
                        </p>
                      )}

                    {animationStage > 1 &&
                      animationStage < 4 &&
                      index === 0 &&
                      index2 === rows.length - 2 && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                            position: `relative`,
                            left: `${rectangleWidth / columns.length / 3}px`,
                          }}
                        >
                          +
                        </p>
                      )}
                    {/* this is the regrouping values for thousands */}
                    {animationStage === 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 4 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.thousands}
                        </p>
                      )}
                    {/* This is the regrouping values for hundreds: */}
                    {animationStage === 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 3 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.hundreds}
                        </p>
                      )}
                    {/* This is the regrouping values for tens: */}

                    {animationStage == 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 2 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.tens}
                        </p>
                      )}

                    {/* This is the regrouping values for ones: */}

                    {animationStage == 4 &&
                      index2 === rows.length - 1 &&
                      index === columns.length - 1 &&
                      regroupedObject?.valid && (
                        <p
                          style={{
                            fontSize: `${16}px`,
                          }}
                        >
                          {regroupedObject.ones}
                        </p>
                      )}
                  </div>
                );
              })}
            </div>
          );
        })}

        {animationStage > 1 && (
          <p
            className={classes.secondValue}
            style={{
              fontSize: `${16}px`,
              top: rectangleHeight / 3,
            }}
          >
            {secondValue}
          </p>
        )}
      </div>
    </div>
  );
}

export default PlaceValueChartDisks;
