import { useState, useEffect } from "react";
import { CalculatorStateProvider } from "../../contexts/CalculatorStateProvider";
import classes from "./index.module.css";

import Calculator from "../../components/calculator/Calculator";
import Choices from "../../components/calculator/Choices";
import calculateQuery from "../../javascript/calculateQuery";
import Information from "../../components/calculator/Information";
import { useContext } from "react";
import { ModalContext } from "../../contexts/ModalContextProvider";
import Modal from "../../components/layout/Modal";
import Layout from "../../components/layout/Layout";

function CalculatorPage() {
  const [query, setQuery] = useState("");
  // as with the other components, solution is an object including everything else (like query)
  const [solution, setSolution] = useState({});

  const { isModalOpen, closeModal, openModal } = useContext(ModalContext);
  const [informationModalVisible, setInformationModalVisible] = useState(false);

  function onUpdateQuery(value) {
    setQuery(() => {
      return value;
    });
  }
  function onUpdateSolution(value) {
    setSolution(() => {
      return value;
    });
  }

  function onResetQuery() {
    const resetValue = "";
    setQuery(() => {
      return resetValue;
    });
  }
  const informationClickHandler = () => {
    // setInformationModalVisible((current) => !current);
    if (isModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  return (
    <CalculatorStateProvider>
      <Layout>
        <Modal></Modal>
        <div className={isModalOpen ? `on` : `off`}>
          <Calculator
            updateQuery={onUpdateQuery}
            updateSolution={onUpdateSolution}
            onInformationClick={informationClickHandler}
            resetQuery={onResetQuery}
          />
          {informationModalVisible && <Information></Information>}
          {solution.valid && <Choices />}
          {solution.valid === false && (
            <p className={classes.errorAlert}>
              {" "}
              This calculator can't solve that question. For more instructions
              on how to use this calculator, click the information button (i)
              above.
            </p>
          )}
        </div>
      </Layout>
    </CalculatorStateProvider>
  );
}
export default CalculatorPage;
