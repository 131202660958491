import { useContext } from "react";

import { ModalContext } from "../../contexts/ModalContextProvider"; // Your modal context
import classes from "./Modal.module.css";

function Modal() {
  const { isModalOpen, closeModal, openModal } = useContext(ModalContext);
  return (
    <>
      {isModalOpen && (
        <div>
          <div className={classes["modal-bg"]} onClick={closeModal} />

          <div className={classes.modal}>
            <button className={classes.closeX} onClick={closeModal}>
              &times;
            </button>

            <p>
              Think of this as a very simple calculator that only solves
              multiplication problems (for now). You should enter a number, then
              an operation (x or * to represent multiplication) and then another
              number. Do not start with a negative number.
            </p>
            <p>
              You will notice that some strategies are greyed out. The
              Multiplication Array is a good introduction to division, but it is
              not good at multiplying numbers larger than 10.
            </p>
            <p>
              The Area Model Introduction is not good at multiplying numbers
              greater than 20. The Area Model Intermediate can solve up to
              999x999.
            </p>
            <p>
              The Place Value Chart (disks and numbers) can multiply a number up
              to 999 by another number less than 10. To practice regrouping with
              multiplication, we recommend using the Place Value Chart (disks).
            </p>

            {/* <button className={classes.closeButton} onClick={closeModal}>
              <p>Close</p>
            </button> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
