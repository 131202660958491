import React, { useState } from "react";
import axios from "axios";
import classes from "./CreateLeveledLiteracyText.module.css"; // Assuming you have css modules

function CreateLeveledLiteracyText(props) {
  const [inputValue, setInputValue] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/stories/leveled-literacy-text`,
        {
          prompt: inputValue,
        }
      );
      console.log(response.data); // You can handle your response here
    } catch (error) {
      console.error(error);
    }
  };

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <form className={classes.wrapper} onSubmit={submitHandler}>
      <h3> Generate LeveledLiteracy Story</h3>
      <label htmlFor="input1">
        Input the sound you want to work with (like th):
      </label>
      <input id="input1" value={inputValue} onChange={inputChangeHandler} />
      <button type="submit">Submit</button>
    </form>
  );
}

export default CreateLeveledLiteracyText;
