import chooseMultiplicationMethod from "./chooseMultiplicationMethod";
import chooseAdditionMethod from "./chooseAdditionMethod";
function calculateQuery(query) {
  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  // consider adding decimals
  const operandArray = ["+", "-", "*", "/", "x"];
  let i = 0;
  let solution = "";
  let firstValue = "";
  let secondValue = "";
  let operand = "";
  let problem = "";
  let valid = true;
  // check if query is valid:
  for (let j = 0; j < query.length; j++) {
    if (!(query[i] in digits || query[i] in operandArray)) {
      valid = false;
    }
  }
  if (!query[0] in digits) {
    valid = false;
  }
  while (query[i] in digits) {
    firstValue += query[i];
    i++;
  }
  if (query[i] === " ") {
    i++;
  }
  if (operandArray.includes(query[i])) {
    operand = query[i];
    i++;

    if (query[i] === " ") {
      i++;
    }
    if (!(query[i] in digits)) {
      valid = false;
    }

    while (query[i] in digits) {
      secondValue += query[i];
      i++;
    }
    if ((query[i] && query[i] === " ") || (query[i] && query[i] === "=")) {
      i++;
    }
    // if there is anything after the space, then it's not a valid query.
    if (query[i]) {
      valid = false;
    }

    // leave the opportunity for spaces though
  } else {
    valid = false;
    console.log(" the query is not valid");
  }

  problem = firstValue + operand + secondValue;
  if (operand === "*" || operand === "x") {
    solution = Number(firstValue) * Number(secondValue);
  } else if (operand === "/") {
    solution = Number(firstValue) / Number(secondValue);
  } else if (operand === "-") {
    solution = Number(firstValue) - Number(secondValue);
  } else if (operand === "+") {
    solution = Number(firstValue) + Number(secondValue);
  }
  let validOptions = [];
  let bestOption = "";
  // check for validOptions and bestOptions:
  if (operand === "*" || operand === "x") {
    const object = chooseMultiplicationMethod(firstValue, secondValue);
    validOptions = object.validOptions;
    bestOption = object.bestOption;
  }

  if (operand === "+") {
    const object = chooseAdditionMethod(firstValue, secondValue);
    validOptions = object.validOptions;
    bestOption = object.bestOption;
  }

  // fix me: to do: when I add other operations, take this line out:
  if (!(operand === "x" || operand === "*" || operand === "+")) {
    valid = false;
  }

  // If any number is 1000 or above, then it's not valid:
  if (parseInt(firstValue) >= 1000 || parseInt(secondValue) >= 1000) {
    console.log("invalid in calculate query");
    valid = false;
  }
  return {
    bestOption,
    validOptions,
    solution: solution,
    query: problem,
    firstValue: firstValue,
    secondValue: secondValue,
    operand: operand,
    valid: valid,
  };
}

export default calculateQuery;
function solveMultiplication(firstValue, secondValue) {
  return firstValue * secondValue;
}
