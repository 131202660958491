import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import classes from "./ListStories.module.css";
import ReactPaginate from "react-paginate";

import Modal from "../../../components/layout/Modal";
import Layout from "../../../components/layout/Layout";
function ListStories() {
  const [stories, setStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    fetchStories(currentPage);
  }, []);

  const fetchStories = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/stories/early-readers/list?page=${page}`
      );
      setStories(response.data.stories);
      setPageCount(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    fetchStories(selectedPage);
  };

  return (
    <Layout>
      <Modal></Modal>
      <h1> List Stories</h1>
      <h3>
        <Link to="/stories/edit">Create new Story </Link>
      </h3>
      {stories ? (
        stories.map((story, index) => (
          <div key={index}>
            <h2>
              <Link to={`/stories/early-readers/${story._id}`}>
                {story.title}
              </Link>
            </h2>
            <p>{story.author}</p>
            {/* Add more story details here */}
          </div>
        ))
      ) : (
        <p>Loading stories...</p>
      )}
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </Layout>
  );
}

export default ListStories;
