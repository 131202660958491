import classes from "./EditStory.module.css";

import Modal from "../../../components/layout/Modal";
import Layout from "../../../components/layout/Layout";
import { Link } from "react-router-dom";
import StoryEditor from "../../../components/stories/storyEditor/StoryEditor";
import CreateLeveledLiteracyText from "../../../components/stories/storyEditor/CreateLeveledLiteracyText";
function EditStory() {
  return (
    <Layout>
      <Modal></Modal>
      <h1> Edit Stories</h1>

      <h3>
        <Link to="/stories/list">List stories</Link>
        <StoryEditor></StoryEditor>
        <CreateLeveledLiteracyText />
      </h3>
    </Layout>
  );
}
export default EditStory;
