import classes from "./BarModel.module.css";
import { useState, useEffect, useRef } from "react";
function MultiplicationArray(props) {
  const { solution, problem, firstValue, secondValue } = props.data;
  const [screenSize, setScreenSize] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [additionDiagramFontSize, setAdditionDiagramFontSize] = useState();
  const [animationStage, setAnimationStage] = useState(0);

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }
    if (!arrayHeight) {
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (screenSize && firstValue) {
      let targetTotalWidth = (screenSize.width * 2) / 3;
      let targetTotalHeight = (screenSize.height * 1) / 3;

      let testWidth = (screenSize.width * 2) / 3 / firstValue;
      let fixedWidth = (screenSize.height * 1) / 3 / secondValue;
      let testHeight = screenSize.height / 2 / secondValue;
      if (animationStage < 3) {
        // eg before we flip the length and width:
        // This is the situation where the drawing fits comfortably
        if (testWidth * secondValue < targetTotalHeight) {
          setImgWidth(testWidth);
        } else if (fixedWidth > 20) {
          // this else statement is to keep the squares from becoming tiny.
          setImgWidth(fixedWidth);
        } else if (30 * firstValue > targetTotalWidth) {
          // if the smallest desired size extends beyond the screen, then we can't allow that
          setImgWidth(testWidth);
        } else {
          // this is the smallest desired size.
          setImgWidth(30);
        }
      }
    }
  }, [screenSize, firstValue, secondValue, animationStage]);

  useEffect(() => {
    setAnimationStage(0);
  }, [firstValue, secondValue]);

  let additionTranslation = `${firstValue}`;
  // FIX ME: This won't work with negative numbers.
  for (let i = 0; i < secondValue - 1; i++) {
    additionTranslation += ` + ${firstValue}`;
  }
  const animationStageArray = [
    {
      text: `Let's try to draw this out. We will start with one group of ${firstValue} teddy bears.  How many groups of ${firstValue} do you think we should have in all?`,
    },
    {
      text: `Let's make a total of ${secondValue} groups. Each group has ${firstValue} teddy bears.
      Instead of multiplying, let's solve this problem by adding the groups together.  We
      can read this problem as: ${additionTranslation} = ${solution}.`,
    },
    {
      text: `Now that we've solved the problem with addition, let's solve the problem with multiplication. We have ${secondValue} equal groups of ${firstValue} teddy bears. We
      can read this problem as: ${secondValue} groups of ${firstValue} teddy
      bears equals ${solution} teddy bears. In other words, ${firstValue} x ${secondValue} = ${solution}.`,
    },
    {
      text: `At first, when we drew out this multiplication problem, we drew a group of ${firstValue} teddy bears. Did you know that instead of making ${secondValue} groups of ${firstValue}, we can make ${firstValue} groups of ${secondValue}? Now we can read the problem as ${firstValue} groups of ${secondValue} is ${solution}. This means that the equation ${firstValue} * ${secondValue}= ${solution} is the same as  ${secondValue} * ${firstValue}= ${solution}.`,
    },
    {
      text: `Let's review. Multiplication is the same thing as adding the same number over and over again. ${firstValue} times ${secondValue} is the same thing as ${firstValue} groups of ${secondValue} teddy bears. Add the groups, and the answer is ${solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let fullRows = [];

  for (let i = 0; i < Number(firstValue); i++) {
    columns.push(i);
  }
  for (let i = 0; i < Number(secondValue); i++) {
    fullRows.push(i);
  }
  // I am shorting this by one because I am adding the first row separately (see jsx)
  let rows = [...fullRows];
  rows.pop();

  let secondValueVerticalOffset;
  // I am assuming width = height
  if (secondValue && imgWidth) {
    secondValueVerticalOffset = (imgWidth * secondValue) / 2;
  }

  // and now for the animations:
  function previousVisual() {
    if (animationStage > 0) {
      setAnimationStage(animationStage - 1);
    }
  }

  function nextVisual() {
    if (animationStage < 4) {
      setAnimationStage(animationStage + 1);
    }
  }
  let rowClasses = `${classes.row}`;
  if (animationStage < 6) {
    rowClasses += ` ${classes.rowGroups}`;
  }
  let imageClasses = ` `;
  let previousButtonClasses = `${classes.stageButton}`;
  if (animationStage === 0) {
    previousButtonClasses += ` ${classes.disabledButton}`;
  }
  let nextButtonClasses = `${classes.stageButton}`;

  if (animationStage === 4) {
    nextButtonClasses += ` ${classes.disabledButton}`;
  }

  return (
    <div className={classes.wrapper}>
      <h1>Multiplication Array</h1>

      <button
        className={previousButtonClasses}
        onClick={previousVisual}
        disabled={animationStage === 0}
      >
        Previous
      </button>
      <span className={classes.step}>Step: {animationStage + 1}</span>
      <button
        className={nextButtonClasses}
        onClick={nextVisual}
        disabled={animationStage === 4}
      >
        Next
      </button>
      <br />

      <p>{animationStageArray[animationStage].text}</p>

      {animationStage < 3 && <p>{firstValue}</p>}
      {animationStage > 2 && <p>{secondValue}</p>}
      <div className={classes.arrayWrapper} ref={arrayHeight}>
        {/* I am adding this first row separately */}
        {animationStage < 3 && (
          <div className={rowClasses} style={{ height: `${imgWidth}px` }}>
            {animationStage < 3 &&
              columns.map(function (f) {
                return (
                  <img
                    src="teddy-bear-no-background.png"
                    key={`${f.toString()}firstRow`}
                    style={{ width: `${imgWidth}px` }}
                    className={imageClasses}
                  />

                  // <h2>teddy bear</h2>
                );
              })}

            <div
              className={classes.addingRowDiagram}
              style={{ fontSize: `${additionDiagramFontSize}` }}
            >
              <p>
                <span className={classes.firstPlus}>+</span>
                {firstValue}
              </p>
            </div>

            {/* if stage is at 4, then we switch orientation */}
          </div>
        )}
        {(animationStage === 1 || animationStage === 2) &&
          rows.map(function (e) {
            return (
              (
                <img
                  src="teddy-bear-no-background.png"
                  key={`${e.toString()}extra`}
                  style={{ width: `${imgWidth}px` }}
                  className={imageClasses}
                />
              ),
              (
                <div
                  className={rowClasses}
                  key={`row${e.toString()}`}
                  style={{ height: `${imgWidth}px` }}
                >
                  {columns.map(function (f) {
                    return (
                      <img
                        src="teddy-bear-no-background.png"
                        key={`${f.toString()}${e.toString()}`}
                        style={{ width: `${imgWidth}px` }}
                        className={imageClasses}
                      />
                      // <h2>teddy bear</h2>
                    );
                  })}
                  <div
                    className={classes.addingRowDiagram}
                    style={{ fontSize: `${additionDiagramFontSize}` }}
                    key={`${e.toString()}RowDiagram`}
                  >
                    <p>+{firstValue}</p>
                    {/* Notice we are taking 2 away because e starts at 0 instead of 1 and then the first row is taken away. */}
                    {e === secondValue - 2 && (
                      <p className={classes.addingRowDiagramSolution}>
                        {solution}
                      </p>
                    )}
                  </div>
                </div>
              )
            );
          })}

        {animationStage > 2 &&
          columns.map(function (e) {
            return (
              <div className={rowClasses} key={`row${e.toString()}`}>
                {fullRows.map(function (f) {
                  return (
                    <img
                      src="teddy-bear-no-background.png"
                      key={`${f.toString()}${e.toString()}`}
                      style={{ width: `${imgWidth}px` }}
                      className={imageClasses}
                    />
                    // <h2>teddy bear</h2>
                  );
                })}
              </div>
            );
          })}

        <p
          className={classes.secondValue}
          style={{ top: `${secondValueVerticalOffset}px` }}
        >
          {animationStage === 2 && secondValue}
        </p>
        <p
          className={classes.secondValue}
          style={{ top: `${secondValueVerticalOffset}px` }}
        >
          {animationStage > 2 && firstValue}
        </p>
      </div>
    </div>
  );
}

export default MultiplicationArray;
