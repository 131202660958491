import { useCalculatorState } from "../../contexts/CalculatorStateProvider";
import classes from "./AnimationStage.module.css";

function AnimationStage() {
  const [state, dispatch] = useCalculatorState();
  const { animationStage, maxAnimationStage } = state;
  function previousVisual() {
    if (animationStage > 0) {
      dispatch({ type: "DECREMENT_STAGE" });
    }
  }

  function nextVisual() {
    if (animationStage < maxAnimationStage) {
      dispatch({ type: "INCREMENT_STAGE" });
    }
  }

  let previousButtonClasses = `${classes.stageButton}`;
  if (animationStage === 0) {
    previousButtonClasses += ` ${classes.disabledButton}`;
  }
  let nextButtonClasses = `${classes.stageButton}`;

  if (animationStage === maxAnimationStage) {
    nextButtonClasses += ` ${classes.disabledButton}`;
  }
  return (
    <div>
      <button
        className={previousButtonClasses}
        onClick={previousVisual}
        disabled={animationStage === 0}
      >
        Previous
      </button>
      <span className={classes.step}>Step: {animationStage + 1}</span>
      <button
        className={nextButtonClasses}
        onClick={nextVisual}
        disabled={animationStage === maxAnimationStage}
      >
        Next
      </button>
    </div>
  );
}
export default AnimationStage;
