import classes from "./Card.module.css";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <Link className={classes.linkWrapper} to={props.link}>
      <div className={classes.card}>
        <div className={classes.imgContainer}>
          <img src="/calculator-clipart.png" alt="calculator"></img>
        </div>
        <h3 className={classes.title}>{props.title} </h3>
        <p className={classes.text}>{props.text}</p>
      </div>
    </Link>
  );
}

export default Card;
