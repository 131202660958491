import classes from "./MultiplicationArray.module.css";
import { Fragment, useState, useEffect, useRef } from "react";
import { calculateImageSize } from "../../../../javascript/helperFunctions";
import AnimationStage from "../../AnimationStage";
import Instructions from "../../Instructions";

import { useCalculatorState } from "../../../../contexts/CalculatorStateProvider";

function MultiplicationArray() {
  const [state, dispatch] = useCalculatorState();
  const { firstValue, secondValue, solution, query } = state.problem;
  const animationStage = state.animationStage;

  // const { solution, problem, firstValue, secondValue } = props.data;
  const [screenSize, setScreenSize] = useState();
  const [imgWidth, setImgWidth] = useState();
  const [additionDiagramFontSize, setAdditionDiagramFontSize] = useState();
  // const [animationStage, setAnimationStage] = useState(0);

  useEffect(() => {
    if (!screenSize) {
      setScreenSize(getCurrentDimension());
    }
    if (!arrayHeight) {
    }

    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    window.addEventListener("load", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
      window.removeEventListener("load", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (animationStage < 3) {
      if (screenSize && firstValue && secondValue) {
        setImgWidth(calculateImageSize(screenSize, firstValue, secondValue));
      }
      // Now we flip everything:
    } else {
      if (screenSize && firstValue && secondValue) {
        setImgWidth(calculateImageSize(screenSize, secondValue, firstValue));
      }
    }
  }, [screenSize, firstValue, secondValue, animationStage]);

  useEffect(() => {
    dispatch({ type: "SET_MAX_STAGE", value: 4 });
    dispatch({ type: "RESET_CURRENT_STAGE" });
  }, [firstValue, secondValue]);
  // This sets the addition font size:
  useEffect(() => {
    if (imgWidth) {
      let fontSize = imgWidth / 1.8;
      if (fontSize > 20) {
        fontSize = 20;
      }
      setAdditionDiagramFontSize(`${fontSize}`);
    }
  }, [screenSize, firstValue, secondValue, imgWidth]);

  let additionTranslation = `${firstValue}`;
  // FIX ME: This won't work with negative numbers.
  for (let i = 0; i < secondValue - 1; i++) {
    additionTranslation += ` + ${firstValue}`;
  }

  const countedValues = Array.from(
    { length: secondValue },
    (_, i) => (i + 1) * firstValue
  ).join(", ");

  const animationStageArray = [
    {
      text: `To solve ${query}, let's try to draw it out first. We will start with one group of ${firstValue} robots.  How many groups of ${firstValue} do you think we should have in all?`,
    },
    {
      text: `Let's make a total of ${secondValue} groups. Each group has ${firstValue} robots.
      Instead of multiplying, let's solve this problem by adding the groups together.  We
      can read this problem as: ${additionTranslation} = ${solution}.`,
    },
    {
      text: `Now that we've solved the problem with addition, let's solve the problem with multiplication. We have ${secondValue} equal groups of ${firstValue} robots. We
      can read this problem as: ${secondValue} groups of ${firstValue} teddy
      bears equals ${solution} robots. In other words, ${firstValue} x ${secondValue} = ${solution}. You might think of this as counting by ${firstValue}. Count along: ${countedValues}. Notice that we stopped after listing ${secondValue} numbers.`,
    },
    {
      text: `At first, when we drew out this multiplication problem, we drew a group of ${firstValue} robots. Did you know that instead of making ${secondValue} groups of ${firstValue}, we can make ${firstValue} groups of ${secondValue}? Now we can read the problem as ${firstValue} groups of ${secondValue} is ${solution}. This means that the equation ${firstValue} x ${secondValue}= ${solution} is the same as  ${secondValue} x ${firstValue}= ${solution}.`,
    },
    {
      text: `Let's review. Multiplication is the same thing as adding the same number over and over again. ${firstValue} times ${secondValue} is the same thing as ${firstValue} groups of ${secondValue} robots. To find your answer, you could count by ${firstValue}s ${secondValue} times like this: ${countedValues}. Or you could count by ${secondValue}s ${firstValue} times. Or, you could draw it out in groups and count everything.  Your answer should be ${solution}.`,
    },
  ];

  const arrayHeight = useRef();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  let columns = [];
  let fullRows = [];

  for (let i = 0; i < Number(firstValue); i++) {
    columns.push(i);
  }
  for (let i = 0; i < Number(secondValue); i++) {
    fullRows.push(i);
  }
  // I am shorting this by one because I am adding the first row separately (see jsx)
  let rows = [...fullRows];
  rows.pop();

  let secondValueVerticalOffset;
  // I am assuming width = height
  if (secondValue && imgWidth) {
    secondValueVerticalOffset = (imgWidth * secondValue) / 2;
  }

  // and now for the animations:

  let rowClasses = `${classes.row}`;
  if (animationStage < 6) {
    rowClasses += ` ${classes.rowGroups}`;
  }
  let imageClasses = ` `;

  function returnImg(e, f, str) {
    return (
      <img
        src="robot-no-background.png"
        alt="A friendly robot"
        key={`${e.toString()} ${f.toString()} ${str ? str : ""}`}
        style={{ width: `${imgWidth}px` }}
        className={imageClasses}
      />
    );
  }

  return (
    <div className={classes.wrapper}>
      <AnimationStage />
      <br />
      <Instructions text={animationStageArray[animationStage]?.text} />

      {animationStage < 3 && (
        <p
          style={{
            fontSize: `${additionDiagramFontSize}px`,
          }}
        >
          {firstValue}
        </p>
      )}
      {animationStage > 2 && (
        <p
          style={{
            fontSize: `${additionDiagramFontSize}px`,
          }}
        >
          {secondValue}
        </p>
      )}
      <div className={classes.arrayWrapper} ref={arrayHeight}>
        {/* I am adding this first row separately */}
        {animationStage < 3 && (
          <div className={rowClasses} style={{ height: `${imgWidth}px` }}>
            {animationStage < 3 &&
              columns.map(function (f) {
                return returnImg("un", f, "firstRow");
              })}

            <div
              className={classes.addingRowDiagram}
              style={{ fontSize: `${additionDiagramFontSize}px` }}
            >
              {animationStage > 0 && (
                <p>
                  <span className={classes.firstPlus}>+</span>
                  {firstValue}
                </p>
              )}
            </div>

            {/* if stage is at 4, then we switch orientation */}
          </div>
        )}
        {(animationStage === 1 || animationStage === 2) &&
          rows.map(function (e) {
            return (
              returnImg(e, "?", "extra"),
              (
                <div
                  className={rowClasses}
                  key={`row${e.toString()}`}
                  style={{ height: `${imgWidth}px` }}
                >
                  {columns.map(function (f) {
                    return returnImg(e, f, "another");
                  })}
                  <div
                    className={classes.addingRowDiagram}
                    style={{ fontSize: `${additionDiagramFontSize}px` }}
                    key={`${e.toString()}RowDiagram`}
                  >
                    <p>+{firstValue}</p>
                    {/* Notice we are taking 2 away because e starts at 0 instead of 1 and then the first row is taken away. */}
                    {e === secondValue - 2 && (
                      <p className={classes.addingRowDiagramSolution}>
                        {solution}
                      </p>
                    )}
                  </div>
                </div>
              )
            );
          })}

        {animationStage > 2 &&
          columns.map(function (e) {
            return (
              <div className={rowClasses} key={`row${e.toString()}`}>
                {animationStage > 2 && e === Math.floor(columns.length / 2) && (
                  <p
                    className={classes.newSecondValue}
                    style={{
                      fontSize: `${additionDiagramFontSize}px`,
                    }}
                  >
                    {firstValue}
                  </p>
                )}

                {fullRows.map(function (f) {
                  return returnImg(e, f, "another2");
                })}

                {animationStage === 4 &&
                  e === Math.floor(columns.length / 2) && (
                    <div className={classes.multiplicationRightContainer}>
                      <p
                        className={classes.multiplicationRight}
                        style={{ fontSize: `${additionDiagramFontSize}px` }}
                      >
                        {firstValue}
                        <br />
                        <span className={classes.underlined}>
                          {" "}
                          x{secondValue}
                        </span>{" "}
                        <br />
                        {solution}
                      </p>
                    </div>
                  )}
              </div>
            );
          })}

        <p
          className={classes.secondValue}
          style={{
            top: `${secondValueVerticalOffset}px`,
            fontSize: `${additionDiagramFontSize}px`,
          }}
        >
          {animationStage === 2 && secondValue}
        </p>
      </div>
    </div>
  );
}

export default MultiplicationArray;
