import { useState, Fragment, useEffect } from "react";
import classes from "./choices.module.css";
import Solution from "./solution/Solution";
import MultiplicationArray from "./visualMethods/multiplication/MultiplicationArray";
import BarModel from "./visualMethods/multiplication/BarModel";
import AreaModel from "./visualMethods/multiplication/AreaModel";
import AreaModelIntermediate from "./visualMethods/multiplication/AreaModelIntermediate";
import allMultiplicationStrategies from "../../javascript/allMultiplicationStrategies";
import MultiplicationPlaceValueChartDisks from "./visualMethods/multiplication/PlaceValueChartDisks";
import MultiplicationPlaceValueChartNumbers from "./visualMethods/multiplication/PlaceValueChartNumbers";
import AdditionPlaceValueChartDisks from "./visualMethods/addition/PlaceValueChartDisks";
import AdditionPlaceValueChartNumbers from "./visualMethods/addition/PlaceValueChartNumbers";
import { useCalculatorState } from "../../contexts/CalculatorStateProvider";
function Choices(props) {
  const [selectedChoice, setSelectedChoice] = useState("solution");
  const [validOptionsArray, setValidOptionsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [state, dispatch] = useCalculatorState();

  const query = state.problem.query;
  // solution actually holds everything, including the query, validOptions, etc.
  const solution = state.problem;
  console.log("solution in choices", solution);

  useEffect(() => {
    if (solution) {
      setValidOptionsArray(solution.validOptions);
    }
  }, [solution]);
  console.log(validOptionsArray, "validOptions");
  useEffect(() => {
    if (query && solution) {
      if (solution.bestOption === null) {
        setSelectedChoice("solution");
      } else {
        setSelectedChoice(solution.bestOption);
      }
    } else {
      setSelectedChoice("solution");
    }
  }, [query, solution]);

  const handleOptionChange = (event) => {
    dispatch({ type: "RESET_CURRENT_STAGE" });
    setSelectedChoice(event.target.value);
  };

  return (
    <Fragment>
      <div className={classes.selectWrapper}>
        <label htmlFor="dropdown">Choose a Visual Strategy: </label>
        <select
          id="dropdown"
          value={selectedChoice}
          onChange={handleOptionChange}
        >
          <option value="solution">Solution</option>

          {/* All of these options are multiplication options: only visible if the operand is multiplication. */}
          {(solution.operand === "x" || solution.operand === "*") && (
            <Fragment>
              <option
                value="multiplicationArray"
                disabled={!validOptionsArray?.includes("multiplicationArray")}
              >
                Multiplication Array
              </option>
              <option
                value="areaModelIntroduction"
                disabled={!validOptionsArray?.includes("areaModelIntroduction")}
              >
                Area Model Introduction
              </option>
              <option
                value="areaModelIntermediate"
                disabled={!validOptionsArray?.includes("areaModelIntermediate")}
              >
                Area Model Intermediate
              </option>
              <option
                value="multiplicationPlaceValueChartDisks"
                disabled={
                  !validOptionsArray?.includes(
                    "multiplicationPlaceValueChartDisks"
                  )
                }
              >
                Place Value Chart (Disks)
              </option>
              <option
                value="multiplicationPlaceValueChartNumbers"
                disabled={
                  !validOptionsArray?.includes(
                    "multiplicationPlaceValueChartNumbers"
                  )
                }
              >
                Place Value Chart (Numbers)
              </option>
            </Fragment>
          )}

          {/* All of these options are addition options: only visible if the operand is + */}
          {solution.operand === "+" && (
            <Fragment>
              <option
                value="additionPlaceValueChartDisks"
                disabled={
                  !validOptionsArray?.includes("additionPlaceValueChartDisks")
                }
              >
                Place Value Chart (Disks)
              </option>
              <option
                value="additionPlaceValueChartNumbers"
                disabled={
                  !validOptionsArray?.includes("additionPlaceValueChartNumbers")
                }
              >
                Place Value Chart (Numbers)
              </option>
            </Fragment>
          )}
        </select>
      </div>

      {selectedChoice === "solution" && query && (
        <Solution query={query}></Solution>
      )}
      {selectedChoice === "multiplicationArray" && query && (
        <MultiplicationArray />
      )}
      {selectedChoice === "areaModelIntroduction" && query && <AreaModel />}
      {selectedChoice === "areaModelIntermediate" && query && (
        <AreaModelIntermediate />
      )}
      {selectedChoice === "multiplicationPlaceValueChartDisks" && query && (
        <MultiplicationPlaceValueChartDisks />
      )}
      {selectedChoice === "multiplicationPlaceValueChartNumbers" && query && (
        <MultiplicationPlaceValueChartNumbers />
      )}
      {selectedChoice === "additionPlaceValueChartNumbers" && query && (
        <AdditionPlaceValueChartNumbers />
      )}
      {selectedChoice === "additionPlaceValueChartDisks" && query && (
        <AdditionPlaceValueChartDisks />
      )}

      {/* {selectedChoice === "barModel" && query && <BarModel data={solution} />} */}
    </Fragment>
  );
}
export default Choices;
