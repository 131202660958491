import React, { createContext, useReducer, useContext } from "react";

// Initial state
const initialState = {
  problem: {},
  maxAnimationStage: 1,
  animationStage: 0,
  animateColumn: 3,
};

// Define a context and a reducer for updating the context
const CalculatorStateContext = createContext(initialState);

const CalculatorStateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PROBLEM": // Define your action types as needed
      return {
        animationStage: 0,
        animateColumn: 3,
        maxAnimationStage: state.maxAnimationStage,
        problem: action.value,
      };
    case "INCREMENT_STAGE": // Define your action types as needed
      if (state.animationStage < state.maxAnimationStage) {
        return {
          ...state,
          animationStage: state.animationStage + 1,
          animateColumn: 3,
        };
      } else {
        return {
          ...state,
        };
      }
    case "SET_MAX_STAGE": // Define your action types as needed
      if (action.value) {
        return {
          ...state,
          maxAnimationStage: action.value,
        };
      } else {
        return {
          ...state,
        };
      }
    case "RESET_CURRENT_STAGE": // Define your action types as needed
      return {
        ...state,
        animateColumn: 3,
        animationStage: 0,
      };

    case "DECREMENT_STAGE": // Define your action types as needed
      if (state.animationStage > 0) {
        return {
          ...state,
          animationStage: state.animationStage - 1,
          animateColumn: 3,
          // set limit here.
        };
      } else {
        return {
          ...state,
        };
      }
    case "ANIMATE_COLUMN": // Define your action types as needed
      if (state.animateColumn > 0) {
        return {
          ...state,
          animateColumn: state.animateColumn - 1,
          // set limit here.
        };
      } else {
        return {
          ...state,
        };
      }

    default:
      return state;
  }
};

// Define a provider for the calculator context
export const CalculatorStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CalculatorStateReducer, initialState);

  return (
    <CalculatorStateContext.Provider value={[state, dispatch]}>
      {children}
    </CalculatorStateContext.Provider>
  );
};

// Custom hook to use the calculator state context
export const useCalculatorState = () => useContext(CalculatorStateContext);
